import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Customers & Suppliers',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Customers & Suppliers',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Customers & Suppliers',
  SET_CS_REGISTRY: '[SET_CS_REGISTRY] Customers & Suppliers',
  SET_CS_ADDRESS: '[SET_CS_ADDRESS] Customers & Suppliers',
  CLEAR_REGISTRY_ADDRESS: '[CLEAR_REGISTRY_ADDRESS Customers & Suppliers',
  //
  DELETE_START: '[DELETE_START] Customers & Suppliers',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Customers & Suppliers',
  DELETE_FAIL: '[DELETE_FAIL] Customers & Suppliers',
  //
  ADD_START: '[ADD_START] Customers & Suppliers',
  ADD_SUCCESS: '[ADD_SUCCESS] Customers & Suppliers',
  ADD_FAIL: '[ADD_FAIL] Customers & Suppliers',
  //
  UPDATE_START: '[UPDATE_START] Customers & Suppliers',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Customers & Suppliers',
  UPDATE_FAIL: '[UPDATE_FAIL] Customers & Suppliers',
  //
  GET_START: '[GET_START] Customers & Suppliers',
  GET_SUCCESS: '[GET_SUCCESS] Customers & Suppliers',
  GET_FAIL: '[GET_FAIL] Customers & Suppliers',
  CLEAR_CS_DATA: '[CLEAR_CS_DATA] Customers & Suppliers',

  // ** New CS
  GET_CS: '[GET_CS] Customers & Suppliers',
  CLEAR_GET_CS: '[CLEAR_GET_CS] Customers & Suppliers',
  SET_LEGAL_ENTITIES: '[SET_LEGAL_ENTITIES] Customers & Suppliers',
  SET_COUNTRIES: '[SET_COUNTRIES] Customers & Suppliers',
  CLEAR_LEGAL_ENTITIES: '[CLEAR_LEGAL_ENTITIES] Customers & Suppliers',
  CLEAR_COUNTRIES: '[CLEAR_COUNTRIES] Customers & Suppliers',
  UPDATE_IS_PHYSICAL_PERSON: '[UPDATE_IS_PHYSICAL_PERSON] Customers & Suppliers',
  CLEAR_IS_PHYSICAL_PERSON: '[CLEAR_IS_PHYSICAL_PERSON] Customers & Suppliers',
  UPDATE_SELECTED_COUNTRY: '[UPDATE_SELECTED_COUNTRY] Customers & Suppliers',
  CLEAR_SELECTED_COUNTRY: '[CLEAR_SELECTED_COUNTRY] Customers & Suppliers',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http('api/customers-suppliers')
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_START, payload: index })

    http(`api/customers-suppliers/${id}`, { method: 'delete' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="deletedCS" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteCSFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = (payload, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('api/customers-suppliers', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newCS" />))
        history.push('/customers-suppliers')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
        dispatch({ type: ActionTypes.CLEAR_REGISTRY_ADDRESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newCStFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ csId, payload }, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/customers-suppliers/${csId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedCS" />))
        history.push('/customers-suppliers')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
        dispatch({ type: ActionTypes.CLEAR_REGISTRY_ADDRESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateCSFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: id })
    http(`api/customers-suppliers/${id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearCSData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CS_DATA })
  }
}

export const setCSRegistry = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_CS_REGISTRY, payload })
  }
}

export const setCSAddress = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_CS_ADDRESS, payload })
  }
}

// ** New CS
export const setCSCreate = () => {
  return (dispatch) => {
    http('api/customers-suppliers/create').then((response) => {
      const { legal_entities, countries } = response
      dispatch({ type: ActionTypes.SET_LEGAL_ENTITIES, payload: legal_entities })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
    })
  }
}

export const clearCSCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_LEGAL_ENTITIES })
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
  }
}

export const addCS = ({ payload, history }) => {
  return (dispatch) => {
    http('api/customers-suppliers', { method: 'post', body: payload })
      .then((response) => {
        dispatch(showToast(<InlineToast title="Success" description="newCS" />))
        history.push('/customers-suppliers')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload: response })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newCSFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateCS = ({ payload, history, id }) => {
  return (dispatch) => {
    http(`api/customers-suppliers/${id}`, { method: 'put', body: payload })
      .then((response) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedCS" />))
        history.push('/customers-suppliers')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateCSFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getCS = (id) => {
  return (dispatch) => {
    http(`api/customers-suppliers/${id}/edit`).then((response) => {
      const { countries, customer_supplier, legal_entities } = response
      dispatch({ type: ActionTypes.SET_LEGAL_ENTITIES, payload: legal_entities })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.GET_CS, payload: customer_supplier })
    })
  }
}

export const clearGetCS = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_GET_CS })
  }
}

export const updateIsPhysicalPerson = (state) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_IS_PHYSICAL_PERSON, payload: state })
  }
}

export const clearIsPhysicalPerson = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_IS_PHYSICAL_PERSON })
  }
}

export const updateSelectedCountry = (value) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SELECTED_COUNTRY, payload: value })
  }
}

export const clearSelectedCountry = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_COUNTRY })
  }
}
