import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] PAYMENTS',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] PAYMENTS',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] PAYMENTS',
  //
  DELETE_START: '[DELETE_START] PAYMENTS',
  DELETE_SUCCESS: '[DELETE_SUCCESS] PAYMENTS',
  DELETE_FAIL: '[DELETE_FAIL] PAYMENTS',
  //
  ADD_START: '[ADD_START] PAYMENTS',
  ADD_SUCCESS: '[ADD_SUCCESS] PAYMENTS',
  ADD_FAIL: '[ADD_FAIL] PAYMENTS',
  //
  UPDATE_START: '[UPDATE_START] PAYMENTS',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] PAYMENTS',
  UPDATE_FAIL: '[UPDATE_FAIL] PAYMENTS',
  //
  GET_START: '[GET_START] PAYMENTS',
  GET_SUCCESS: '[GET_SUCCESS] PAYMENTS',
  GET_FAIL: '[GET_FAIL] PAYMENTS',
  SET_PAYMENT_DATA: '[SET_PAYMENT_DATA] PAYMENTS',
  CLEAR_PAYMENT_DATA: '[CLEAR_PAYMENT_DATA] PAYMENTS',
}

export const getList = ({ limit, page }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`/api/payments?per_page=${limit}&page=${page}`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('api/payments', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newPayment" />))
        history.push('/paymentss')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newPaymentFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, movementId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/payments/${movementId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedPayment" />))
        history.push('/payments')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updatePaymentFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (paymentId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: paymentId })

    http(`api/payments/${paymentId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setPaymentData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_PAYMENT_DATA, payload })
  }
}

export const clearPaymentData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_FAIL })
  }
}
