import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  itemData: {},

  // new item
  attributes: [],
  selectedAttributes: [],
  itemCreate: {},
  itemEdit: {},
  taxes: [],
  deducibilities: [],
  unitMeasures: [],
}

export const items = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((item) => item.id !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.SET_ITEM_DATA:
      return {
        ...state,
        itemData: { ...state.itemData, ...payload },
      }

    case ActionTypes.SET_UNIT_MEASURE:
      return {
        ...state,
        unitMeasures: payload,
      }

    case ActionTypes.SET_TAXES:
      return {
        ...state,
        taxes: payload,
      }

    case ActionTypes.SET_DEDUCIBILITIES:
      return {
        ...state,
        deducibilities: payload,
      }

    case ActionTypes.SET_ITEM_EDIT:
      return {
        ...state,
        itemEdit: payload,
      }

    case ActionTypes.SET_ATTRIBUTES:
      return {
        ...state,
        attributes: payload,
      }

    case ActionTypes.SET_ITEM_CREATE:
      return {
        ...state,
        itemCreate: payload,
      }

    case ActionTypes.SET_SELECTED_ATTRIBUTES:
      return {
        ...state,
        selectedAttributes: payload,
      }

    case ActionTypes.UPDATE_ITEM_CODE:
      return {
        ...state,
        itemCreate: {
          ...state.itemCreate,
          code: payload,
        },
      }

    case ActionTypes.UPDATE_ITEM_TYPE:
      return {
        ...state,
        itemCreate: {
          ...state.itemCreate,
          itemType: payload,
        }
      }

    case ActionTypes.CLEAR_ATTRIBUTES:
      return {
        ...state,
        attributes: [],
      }

    case ActionTypes.CLEAR_SELECTED_ATTRIBUTES:
      return {
        ...state,
        selectedAttributes: [],
      }

    case ActionTypes.CLEAR_ITEM_CREATE:
      return {
        ...state,
        itemCreate: {},
        deducibilities: [],
        taxes: [],
        unitMeasures: [],
      }

    case ActionTypes.CLEAR_ITEM_EDIT:
      return {
        ...state,
        itemEdit: {},
      }

    case ActionTypes.CLEAR_ITEM_DATA:
      return {
        ...state,
        itemData: {},
        unitMeasures: [],
        taxes: [],
        deducibilities: [],
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.CLEAR_CONTRACT_ITEMS:
      return {
        ...state,
        itemData: {},
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    default:
      return state
  }
}
