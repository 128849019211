// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
// import messagesDe from '@assets/data/locales/de.json'
//import messagesFr from '@assets/data/locales/fr.json'
import messagesIt from '@assets/data/locales/it.json'
// import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
// import userMessagesDe from '@src/assets/data/locales/de.json'
//import userMessagesFr from '@src/assets/data/locales/fr.json'
// import userMessagesPt from '@src/assets/data/locales/pt.json'
import userMessagesIt from '@src/assets/data/locales/it.json'
import { updateLanguage, getUserDetails } from '@services/user'

// ** Menu msg obj
const menuMessages = {
  // TODO: Add translations
  en: { ...messagesEn, ...userMessagesEn },
  // de: { ...messagesDe, ...userMessagesDe },
  it: { ...messagesIt, ...userMessagesIt },
  // pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  //const [locale, setLocale] = useState('en')
  const [locale, setLocale] = useState('it')
  const [messages, setMessages] = useState(menuMessages['it'])

  // ** Switches Language
  const switchLanguage = (language) => {
    setLocale(language)
    setMessages(menuMessages[language])
    updateLanguage({ language })
  }

  const getDefaultLanguage = async () => {
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    if (userData?.id) {
      const apiResponse = await getUserDetails(userData.id)
      if (apiResponse?.data) {
        setLocale(apiResponse.data.default_language)
        setMessages(menuMessages[apiResponse?.data?.default_language])
        updateLanguage({ language: apiResponse.data.default_language })
      }
    }
  }

  useEffect(() => {
    getDefaultLanguage()
  }, [])

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="it">
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
