import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  SET_FORM_DATA: '[SET_FORM_DATA] Office Authorization',
  CLEAR_FORM_DATA: '[SET_FORM_DATA] Office Authorization',
  //
  LOAD_LIST_START: '[LOAD_LIST_START] Office Authorization',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Office Authorization',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Office Authorization',
  //
  DELETE_START: '[DELETE_START] Office Authorization',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Office Authorization',
  DELETE_FAIL: '[DELETE_FAIL] Office Authorization',
  //
  UPDATE_START: '[UPDATE_START] Office Authorization',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Office Authorization',
  UPDATE_FAIL: '[UPDATE_FAIL] Office Authorization',
  //
  GET_START: '[GET_START] Office Authorization',
  GET_SUCCESS: '[GET_SUCCESS] Office Authorization',
  GET_FAIL: '[GET_FAIL] Office Authorization',
  //
  ADD_START: '[ADD_START] Office Authorization',
  ADD_SUCCESS: '[ADD_SUCCESS] Office Authorization',
  ADD_FAIL: '[ADD_FAIL] Office Authorization',
  CLEAR_DATA: '[CLEAR_DATA] Office Authorization ',
}

export const getList = (officeId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`api/offices/${officeId}/authorizations`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const updateItem = ({ payload, history, officeId, userId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/offices/${officeId}/authorizations/${userId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedAuthorization" />))
        history.push(`/companies/offices/${officeId}/authorization`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateAuthorizationFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const deleteItem = ({ userId, officeId, index }) => {
  return (dispatch) => {
    http(`api/offices/${officeId}/authorizations/${userId}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedAuthorization" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteAuthorizationFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = ({ userId, officeId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: userId })

    http(`api/offices/${officeId}/authorizations/${userId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const addItem = ({ payload, history, officeId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http(`api/offices/${officeId}/authorizations`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="setAuthorization" />))
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
        history.push(`/companies/offices/${officeId}/authorization/`)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="setAuthorizationFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const setFormData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_FORM_DATA, payload })
  }
}

export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_DATA })
  }
}

export const clearForm = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_FORM_DATA })
  }
}
