import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] SIMULATIONS',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] SIMULATIONS',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] SIMULATIONS',
  //
  DELETE_START: '[DELETE_START] SIMULATIONS',
  DELETE_SUCCESS: '[DELETE_SUCCESS] SIMULATIONS',
  DELETE_FAIL: '[DELETE_FAIL] SIMULATIONS',
  //
  ADD_START: '[ADD_START] SIMULATIONS',
  ADD_SUCCESS: '[ADD_SUCCESS] SIMULATIONS',
  ADD_FAIL: '[ADD_FAIL] SIMULATIONS',
  //
  UPDATE_START: '[UPDATE_START] SIMULATIONS',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] SIMULATIONS',
  UPDATE_FAIL: '[UPDATE_FAIL] SIMULATIONS',
  //
  GET_START: '[GET_START] SIMULATIONS',
  GET_SUCCESS: '[GET_SUCCESS] SIMULATIONS',
  GET_FAIL: '[GET_FAIL] SIMULATIONS',
  SET_SIMULATION_DATA: '[SET_SIMULATION_DATA] SIMULATIONS',
  CLEAR_SIMULATION_DATA: '[CLEAR_SIMULATION_DATA] SIMULATIONS',
  //
  COMPLETE_SUCCESS: '[COMPLETE_SUCCESS] SIMULATIONS',
  COMPLETE_FAIL: '[COMPLETE_FAIL] SIMULATIONS',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/simulation`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    http(`api/simulation/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedSimulation" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteSimulationFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const completeSimulation = ({ id, index }) => {
  return (dispatch) => {
    http(`api/simulation/${id}/complete-simulation`, { method: 'put' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="completedSimulation" />))
        dispatch({ type: ActionTypes.COMPLETE_SUCCESS })
        dispatch(getList())
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="completeSimulationFail" />, 'error'))
      })
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http('api/simulation', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newSimulation" />))
        history.push('/simulations')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newSimulationFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const fillInSimulation = ({ payload, history, simulation_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`/api/simulation/${simulation_id}/save-simulation-wizard`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="filledInSimulation" />))
        history.push('/simulations')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="fillInSimulationFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, simulationId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/simulation/${simulationId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedSimulation" />))
        history.push('/simulations')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateSimulationFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (simulationId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: simulationId })
    http(`api/simulation/${simulationId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setSimulationData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_SIMULATION_DATA, payload })
  }
}

export const clearSimulationData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SIMULATION_DATA })
  }
}
