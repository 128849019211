import { InlineToast } from '@src/views/components/InlineToast'
import { showToast, hidePrompt } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Payments',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Payments',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Payments',
  //
  DELETE_START: '[DELETE_START] Payments',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Payments',
  DELETE_FAIL: '[DELETE_FAIL] Payments',
  //
  ADD_START: '[ADD_START] Payments',
  ADD_SUCCESS: '[ADD_SUCCESS] Payments',
  ADD_FAIL: '[ADD_FAIL] Payments',
  //
  UPDATE_START: '[UPDATE_START] Payments',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Payments',
  UPDATE_FAIL: '[UPDATE_FAIL] Payments',
  //
  GET_START: '[GET_START] Payments',
  GET_SUCCESS: '[GET_SUCCESS] Payments',
  GET_FAIL: '[GET_FAIL] Payments',
  CLEAR_PAYMENT_DATA: '[CLEAR_PAYMENT_DATA] Payments',
  SET_PAYMENT_DATA: '[SET_PAYMENT_DATA] Payments',

  // ** new contract payments
  SET_TYPES: '[SET_TYPES] Payments',
  SET_METHODS: '[SET_METHODS] Payments',
  SET_SELECTED_PAYMENT: '[SET_SELECTED_PAYMENT] Payments',
  CLEAR_TYPES: '[CLEAR_TYPES] Payments',
  CLEAR_METHODS: '[CLEAR_METHODS] Payments',
  CLEAR_SELECTED_PAYMENT: '[CLEAR_SELECTED_PAYMENT] Payments',
}

export const getList = ({ contractId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`api/workers/contracts/${contractId}/payments`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ employeeId, contractId, paymentId, index }) => {
  return (dispatch) => {
    http(`api/workers/contracts/${contractId}/payments/${paymentId}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="paymentDeleted" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="paymentDeleteFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = ({ employeeId, payload, contractId, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`api/workers/contracts/${contractId}/payments`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newPayment" />))
        history.go(-1)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newPaymentFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addMultiplePayments = ({ employeeId, payload, contractId, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`api/workers/contracts/${contractId}/payments/create-multiple-payments`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="paymentsCreated" />))
        history.go(-1)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="paymentCreateFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, employeeId, contractId, paymentId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/workers/contracts/${contractId}/payments/${paymentId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedPayment" />))
        history.go(-1)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updatePaymentFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const terminatePayment = ({ id, payload }) => {
  return (dispatch) => {
    http(`api/terminate-employee-contract/${id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(hidePrompt())
        dispatch(showToast(<InlineToast title="Success" description="paymentsTerminated" />))
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="paymentTerminateFail" />, 'error'))
      })
  }
}

export const getItem = ({ employeeId, contractId, paymentId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: paymentId })
    http(`api/workers/contracts/${contractId}/payments/${paymentId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setPaymentData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_PAYMENT_DATA, payload })
  }
}

export const clearPaymentData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_PAYMENT_DATA })
  }
}

// ** new contract payments
export const addContractPayment = ({ contractId, payload, history }) => {
  return (dispatch) => {
    http(`api/workers/contracts/${contractId}/payments`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newPayment" />))
        history.go(-1)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newPaymentFail" />, 'error'))
      })
  }
}

export const updateContractPayment = ({ contractId, paymentId, payload, history }) => {
  return (dispatch) => {
    http(`api/workers/contracts/${contractId}/payments/${paymentId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedPayment" />))
        history.go(-1)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updatePaymentFail" />, 'error'))
      })
  }
}

export const addContractMultiplePayment = ({contractId, payload, history}) => {
  return dispatch => {
    http(`api/workers/contracts/${contractId}/payments/create-multiple-payments`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="addMultiplePaymentsSuccess" />))
        history.go(-1)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="addMultiplePaymentsError" />, 'error'))
      })
  }
}

export const setContractPaymentsCreate = (contractId) => {
  return (dispatch) => {
    http(`api/workers/contracts/${contractId}/payments/create`).then((response) => {
      const { types, methods } = response
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_METHODS, payload: methods })
    })
  }
}

export const setContractPaymentsEdit = (contractId, paymentId) => {
  return (dispatch) => {
    http(`api/workers/contracts/${contractId}/payments/${paymentId}/edit`).then((response) => {
      const { types, methods, payment } = response
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_METHODS, payload: methods })
      dispatch({ type: ActionTypes.SET_SELECTED_PAYMENT, payload: payment })
    })
  }
}

export const clearContractPaymentsCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_METHODS })
  }
}

export const clearContractPaymentsEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_METHODS })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_PAYMENT })
  }
}
