import { InlineToast } from '@src/views/components/InlineToast'
import { showToast, hidePrompt } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Employee Contracts',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Employee Contracts',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Employee Contracts',
  //
  DELETE_START: '[DELETE_START] Employee Contracts',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Employee Contracts',
  DELETE_FAIL: '[DELETE_FAIL] Employee Contracts',
  //
  ADD_START: '[ADD_START] Employee Contracts',
  ADD_SUCCESS: '[ADD_SUCCESS] Employee Contracts',
  ADD_FAIL: '[ADD_FAIL] Employee Contracts',
  //
  UPDATE_START: '[UPDATE_START] Employee Contracts',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Employee Contracts',
  UPDATE_FAIL: '[UPDATE_FAIL] Employee Contracts',
  //
  GET_START: '[GET_START] Employee Contracts',
  GET_SUCCESS: '[GET_SUCCESS] Employee Contracts',
  GET_FAIL: '[GET_FAIL] Employee Contracts',
  CLEAR_CONTRACT_DATA: '[CLEAR_CONTRACT_DATA] Employee Contracts',
  SET_CONTRACT_DATA: '[SET_CONTRACT_DATA] Employee Contracts',

  // ** New employee contracts
  SET_TYPES: '[SET_TYPES] Employee Contracts',
  SET_SELECTED_CONTRACT: '[SET_SELECTED_CONTRACT] Employee Contracts',
  SET_RECESS_MOTIVATIONS: '[SET_RECESS_MOTIVATIONS] Employee Contracts',
  CLEAR_TYPES: '[CLEAR_TYPES] Employee Contracts',
  CLEAR_SELECTED_CONTRACT: '[CLEAR_SELECTED_CONTRACT] Employee Contracts',
  CLEAR_WORKER_CONTRACTS: '[CLEAR_WORKER_CONTRACTS] Employee Contracts',
  CLEAR_RECESS_MOTIVATIONS: '[CLEAR_RECESS_MOTIVATIONS] Employee Contracts',
}

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/workers/${id}/contracts`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getBudgetContractList = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`api/budgets/${id}/workers-contracts`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getSimulationContractList = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/simulation/${id}/employees-contract`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ employeeId, contractId, index }) => {
  return (dispatch) => {
    http(`api/workers/${employeeId}/contracts/${contractId}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedContract" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const deleteBudgetContractItem = ({ budget_id, contract_id }) => {
  return (dispatch) => {
    http(`api/budgets/${budget_id}/workers-contracts/${contract_id}`, { method: 'delete' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="deletedContract" />))
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteContractFail" />, 'error'))
      })
  }
}

export const deleteSimulationContractItem = ({ simulation_id, contract_id, index }) => {
  return (dispatch) => {
    http(`api/simulation/${simulation_id}/employees-contract/${contract_id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedContract" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = ({ payload, employee_id, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`api/workers/${employee_id}/contracts`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.go(-1)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateEmployeeContract = ({ contract_id, employee_id, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/employees/${employee_id}/contracts/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.go(-1)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addBudgetContractItem = ({ payload, budget_id, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`/api/budget/${budget_id}/employees-contract`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.push(`/budgets/${budget_id}/employee-contracts/`)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addSimulationContractItem = ({ payload, simulation_id, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`api/simulation/${simulation_id}/employees-contract`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.push(`/simulations/${simulation_id}/employee-contracts/`)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ contract_id, budget_id, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`/api/budget/${budget_id}/employees-contract/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.go(-1)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateSimulationContract = ({ contract_id, simulation_id, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`/api/simulation/${simulation_id}/employees-contract/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.go(-1)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const terminateContract = ({ contract_id, payload }) => {
  return (dispatch) => {
    http(`api/workers/${payload.employee_id}/contracts/${contract_id}/terminate`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(hidePrompt())
        dispatch(showToast(<InlineToast title="Success" description="contractTerminated" />))
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="contractTerminateFail" />, 'error'))
      })
  }
}

export const getItem = ({ employee_id, contract_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`api/workers/${employee_id}/contracts/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getBudgetContractItem = ({ budget_id, contract_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`/api/budget/${budget_id}/employees-contract/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getSimulationContractItem = ({ simulation_id, contract_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`/api/simulation/${simulation_id}/employees-contract/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setContractData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_CONTRACT_DATA, payload })
  }
}

export const clearContractData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_DATA })
  }
}

// ** New employee contracts
export const getWorkerContracts = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`api/workers/${id}/contracts`)
      .then((response) => {
        const { recess_motivations, workers } = response
        dispatch({ type: ActionTypes.LOAD_LIST_SUCCESS, payload: workers })
        dispatch({ type: ActionTypes.SET_RECESS_MOTIVATIONS, payload: recess_motivations })
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.LOAD_LIST_ERROR, payload: error.message })
      })
  }
}

export const addWorkerContract = ({ payload, workerId, history }) => {
  return (dispatch) => {
    http(`api/workers/${workerId}/contracts`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.go(-1)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, 'error'))
      })
  }
}

export const updateWorkerContract = ({ payload, workerId, contractId, history }) => {
  return (dispatch) => {
    http(`api/workers/${workerId}/contracts/${contractId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.go(-1)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
      })
  }
}

export const terminateWorkerContract = ({ workerId, contractId, payload }) => {
  return (dispatch) => {
    http(`api/workers/${workerId}/contracts/${contractId}/terminate`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="contractTerminated" />))
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="contractTerminateFail" />, 'error'))
      })
  }
}

export const setWorkerContractCreate = (id) => {
  return (dispatch) => {
    http(`api/workers/${id}/contracts/create`).then((response) => {
      const { types } = response
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
    })
  }
}

export const setWorkerContractEdit = (workerId, contractId) => {
  return (dispatch) => {
    http(`api/workers/${workerId}/contracts/${contractId}/edit`).then((response) => {
      const { contract, types } = response
      dispatch({ type: ActionTypes.SET_SELECTED_CONTRACT, payload: contract })
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
    })
  }
}

export const clearWorkerContracts = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_WORKER_CONTRACTS })
    dispatch({ type: ActionTypes.CLEAR_RECESS_MOTIVATIONS })
  }
}

export const clearWorkerContractCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_TYPES })
  }
}

export const clearWorkerContractEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_CONTRACT })
    dispatch({ type: ActionTypes.CLEAR_TYPES })
  }
}
