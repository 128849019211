import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Permissions',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Permissions',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Permissions',
  //
  GET_START: '[GET_START] Permissions',
  GET_SUCCESS: '[GET_SUCCESS] Permissions',
  GET_FAIL: '[GET_FAIL] Permissions',
}

export const getList = ({ limit = 100, page = 0 } = {}) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http('api/permissions')
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getItem = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload })

    http(`permission/show/${payload}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}
