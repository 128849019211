import { http } from '@utils'

import axios from 'axios'
import { getHeaders } from '../../utility/Utils'

export const ActionTypes = {
  VERIFY_FAIL: '[VERIFY_FAIL] User',
  VERIFY_START: '[VERIFY_START] User',
  VERIFY_SUCCESS: '[VERIFY_SUCCESS] User',
  //
  RESET_VERIFY: '[RESET_VERIFY] User',
  GET_SUCCESS: 'GET_SUCCESS User',
  GET_FAIL: 'GET_FAIL User',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA User',
}

export const verify = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.VERIFY_START, payload })
    http(`/api/users/validate-email`, { method: 'post', body: {email : payload} })
      .then((payload) => {
        dispatch({ type: ActionTypes.VERIFY_SUCCESS, payload: payload.id })
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.VERIFY_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getUser = () => {
  return (dispatch) => {
    http(`api/users`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearUserData = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_USER_DATA,
    })
  }
}

export const resetVerify = () => {
  return (dispatch) => dispatch({ type: ActionTypes.RESET_VERIFY })
}
