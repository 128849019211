import { cloneElement, Fragment } from 'react'
import Avatar from '@components/avatar'
import { Info } from 'react-feather'
import { FormattedMessage } from 'react-intl'

export const InlineToast = ({ title, description, icon = <Info /> }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        {icon && (
          <Avatar
            size="sm"
            icon={cloneElement(icon, { ...icon.props, size: 12 })}
          />
        )}
        <h6 className="toast-title font-weight-bold"><FormattedMessage id={title} /></h6>
      </div>
    </div>
    <div className="toastify-body">
      <span><FormattedMessage id={description} /></span>
    </div>
  </Fragment>
)
