import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Employees',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Employees',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Employees',
  //
  DELETE_START: '[DELETE_START] Employees',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Employees',
  DELETE_FAIL: '[DELETE_FAIL] Employees',
  //
  ADD_START: '[ADD_START] Employees',
  ADD_SUCCESS: '[ADD_SUCCESS] Employees',
  ADD_FAIL: '[ADD_FAIL] Employees',
  //
  UPDATE_START: '[UPDATE_START] Employees',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Employees',
  UPDATE_FAIL: '[UPDATE_FAIL] Employees',
  //
  GET_START: '[GET_START] Employees',
  GET_SUCCESS: '[GET_SUCCESS] Employees',
  GET_FAIL: '[GET_FAIL] Employees',
  CLEAR_EMPLOYEE_DATA: '[CLEAR_EMPLOYEE_DATA] Employees',
  SET_EMPLOYEE_REGISTRY: 'SET_EMPLOYEE_REGISTRY Employees',
  SET_EMPLOYEE_INFO: 'SET_EMPLOYEE_INFO Employees',
  CLEAR_REGISTRY_INFO: 'CLEAR_REGISTRY_INFO Employees',

  // ** New employees (workers)
  SET_CLASSIFICATIONS: '[SET_CLASSIFICATIONS] Employees',
  SET_COUNTRIES: '[SET_COUNTRIES] Employees',
  SET_GENDERS: '[SET_GENDERS] Employees',
  SET_OFFICES_UNITS: '[SET_OFFICES_UNITS] Employees',
  SET_IS_REGISTERED_USER: '[SET_IS_REGISTERED_USER] Employees',
  SET_REGISTERED_USER_DATA: '[SET_REGISTERED_USER_DATA] Employees',
  SET_SELECTED_WORKER: '[SET_SELECTED_WORKER] Employees',
  CLEAR_CLASSIFICATIONS: '[CLEAR_CLASSIFICATIONS] Employees',
  CLEAR_COUNTRIES: '[CLEAR_COUNTRIES] Employees',
  CLEAR_GENDERS: '[CLEAR_GENDERS] Employees',
  CLEAR_OFFICES_UNITS: '[CLEAR_OFFICES_UNITS] Employees',
  CLEAR_REGISTERED_USER_DATA: '[CLEAR_REGISTERED_USER_DATA] Employees',
  CLEAR_IS_REGISTERED_USER: '[CLEAR_IS_REGISTERED_USER] Employees',
  CLEAR_SELECTED_WORKER: '[CLEAR_SELECTED_WORKER] Employees',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/workers`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getAsaEmployees = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/office-asa-employees`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_START, payload: index })

    http(`api/workers/${id}`, { method: 'delete' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="deletedEmployee" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteEmployeeFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = (payload, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http('api/workers', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newEmployee" />))
        history.push('/workers')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newEmployeeFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, id, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/workers/${id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedEmployee" />))
        history.push('/workers')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateEmployeeFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: id })
    http(`api/workers/${id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setEmployeeRegistry = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_EMPLOYEE_REGISTRY, payload })
  }
}

export const setEmployeeInfo = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_EMPLOYEE_INFO, payload })
  }
}

export const clearEmployeeData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_EMPLOYEE_DATA })
    dispatch({ type: ActionTypes.CLEAR_REGISTRY_INFO })
    dispatch({ type: ActionTypes.CLEAR_EMPLOYEE_DATA })
  }
}

// ** New employees
export const addWorker = ({payload, history}) => {
  return dispatch => {
    http('api/workers', { method: 'post', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="newEmployee" />))
        history.push('/workers')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newEmployeeFail" />, 'error'))
      })
  }
}

export const updateWorker = ({payload, id, history}) => {
  return (dispatch) => {
    http(`api/workers/${id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedEmployee" />))
        history.push('/workers')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateEmployeeFail" />, 'error'))
      })
  }
}

export const setWorkerCreate = () => {
  return (dispatch) => {
    http('api/workers/create').then((response) => {
      const { classifications, countries, genders, offices_units } = response
      dispatch({ type: ActionTypes.SET_CLASSIFICATIONS, payload: classifications })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.SET_GENDERS, payload: genders })
      dispatch({ type: ActionTypes.SET_OFFICES_UNITS, payload: offices_units })
    })
  }
}

export const setWorkerEdit = (id) => {
  return dispatch => {
    http(`api/workers/${id}/edit`).then(response => {
      const { classifications, countries, genders, offices_units, worker } = response
      dispatch({ type: ActionTypes.SET_SELECTED_WORKER, payload: worker})
      dispatch({ type: ActionTypes.SET_CLASSIFICATIONS, payload: classifications })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.SET_GENDERS, payload: genders })
      dispatch({ type: ActionTypes.SET_OFFICES_UNITS, payload: offices_units })
    })
  }
}

export const setRegisteredUserData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_IS_REGISTERED_USER, payload: true})
    dispatch({ type: ActionTypes.SET_REGISTERED_USER_DATA, payload })
  }
}

export const clearWorkerCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CLASSIFICATIONS })
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
    dispatch({ type: ActionTypes.CLEAR_GENDERS })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
  }
}

export const clearWorkerEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_WORKER })
    dispatch({ type: ActionTypes.CLEAR_CLASSIFICATIONS })
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
    dispatch({ type: ActionTypes.CLEAR_GENDERS })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
  }
}

export const clearRegisteredUserData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_IS_REGISTERED_USER })
    dispatch({ type: ActionTypes.CLEAR_REGISTERED_USER_DATA })
  }
}
