import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  complete: {
    isLoading: false,
    error: null,
  },
  budgetData: {},

  // ** New budget
  selectedBudget: {},
  selectedWorkerContract: {},
  contractTypes: [],
  deducibilities: [],
  items: [],
  taxes: [],
  unitMeasures: [],
  workers: [],

  allVariants: [],
  itemVariantOptions: [],
  salesVariants: {},
  purchaseVariants: {},
}

export const budgets = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.COMPLETE_SUCCESS:
      return {
        ...state,
        complete: {
          ...state.complete,
          isLoading: false,
          success: true,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      } //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          isLoading: false,
          error: payload,
          data: {},
        },
      }

    case ActionTypes.SET_BUDGET_DATA:
      return {
        ...state,
        budgetData: { ...state.budgetData, ...payload },
      }

    case ActionTypes.CLEAR_BUDGET_DATA:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    // ** New budget
    case ActionTypes.SET_SELECTED_BUDGET:
      return {
        ...state,
        selectedBudget: payload,
      }

    case ActionTypes.SET_SELECTED_WORKER_CONTRACT:
      return {
        ...state,
        selectedWorkerContract: payload,
      }

    case ActionTypes.SET_CONTRACT_TYPES:
      return {
        ...state,
        contractTypes: payload,
      }

    case ActionTypes.SET_DEDUCIBILITIES:
      return {
        ...state,
        deducibilities: payload,
      }

    case ActionTypes.SET_ITEMS:
      return {
        ...state,
        items: payload,
      }

    case ActionTypes.SET_TAXES:
      return {
        ...state,
        taxes: payload,
      }

    case ActionTypes.SET_UNIT_MEASURES:
      return {
        ...state,
        unitMeasures: payload,
      }

    case ActionTypes.SET_WORKERS:
      return {
        ...state,
        workers: payload,
      }

    case ActionTypes.SET_ALL_VARIANTS:
      return {
        ...state,
        allVariants: payload,
      }

    case ActionTypes.SET_ITEM_VARIANT_OPTIONS:
      return {
        ...state,
        itemVariantOptions: payload,
      }

    case ActionTypes.SET_SALES_VARIANTS:
      return {
        ...state,
        salesVariants: payload,
      }

    case ActionTypes.SET_PURCHASE_VARIANTS:
      return {
        ...state,
        purchaseVariants: payload,
      }

    case ActionTypes.CLEAR_SELECTED_BUDGET:
      return {
        ...state,
        selectedBudget: {},
      }

    case ActionTypes.CLEAR_SELECTED_WORKER_CONTRACT:
      return {
        ...state,
        selectedWorkerContract: {},
      }

    case ActionTypes.CLEAR_CONTRACT_TYPES:
      return {
        ...state,
        contractTypes: [],
      }

    case ActionTypes.CLEAR_DEDUCIBILITIES:
      return {
        ...state,
        deducibilities: [],
      }

    case ActionTypes.CLEAR_ITEMS:
      return {
        ...state,
        items: [],
      }

    case ActionTypes.CLEAR_TAXES:
      return {
        ...state,
        taxes: [],
      }

    case ActionTypes.CLEAR_UNIT_MEASURES:
      return {
        ...state,
        unitMeasures: [],
      }

    case ActionTypes.CLEAR_WORKERS:
      return {
        ...state,
        workers: [],
      }

    case ActionTypes.CLEAR_ALL_VARIANTS:
      return {
        ...state,
        allVariants: [],
      }

    case ActionTypes.CLEAR_ITEM_VARIANT_OPTIONS:
      return {
        ...state,
        itemVariantOptions: [],
      }

    case ActionTypes.CLEAR_SALES_VARIANTS:
      return {
        ...state,
        salesVariants: {},
      }

    case ActionTypes.CLEAR_PURCHASE_VARIANTS:
      return {
        ...state,
        purchaseVariants: {},
      }

    default:
      return state
  }
}
