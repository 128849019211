import { ActionTypes } from './actions'

const initialState = {
  verify: {
    error: null,
    isLoading: false,
    verifiedUserId: null,
  },
  get: {},
}

export const user = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.RESET_VERIFY:
      return {
        ...state,
        verify: {
          ...state.verify,
          verifiedUserId: null,
          error: payload,
          isLoading: false,
        },
      }
    //

    case ActionTypes.VERIFY_START:
      return {
        ...state,
        verify: {
          ...state.verify,
          error: null,
          verifiedUserId: null,
          isLoading: true,
        },
      }

    case ActionTypes.VERIFY_SUCCESS:
      return {
        ...state,
        verify: {
          ...state.verify,
          verifiedUserId: payload,
          error: null,
          isLoading: false,
        },
      }

    case ActionTypes.VERIFY_FAIL:
      return {
        ...state,
        verify: {
          ...state.verify,
          verifiedUserId: null,
          error: payload,
          isLoading: false,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.CLEAR_USER_DATA:
      return {
        ...state,
        get: {
          isLoading: false,
          error: payload,
        },
      }

    default:
      return state
  }
}
