import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] STORE MOVEMENTS',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] STORE MOVEMENTS',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] STORE MOVEMENTS',
  //
  DELETE_START: '[DELETE_START] STORE MOVEMENTS',
  DELETE_SUCCESS: '[DELETE_SUCCESS] STORE MOVEMENTS',
  DELETE_FAIL: '[DELETE_FAIL] STORE MOVEMENTS',
  //
  ADD_START: '[ADD_START] STORE MOVEMENTS',
  ADD_SUCCESS: '[ADD_SUCCESS] STORE MOVEMENTS',
  ADD_FAIL: '[ADD_FAIL] STORE MOVEMENTS',
  //
  UPDATE_START: '[UPDATE_START] STORE MOVEMENTS',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] STORE MOVEMENTS',
  UPDATE_FAIL: '[UPDATE_FAIL] STORE MOVEMENTS',
  //
  GET_START: '[GET_START] STORE MOVEMENTS',
  GET_SUCCESS: '[GET_SUCCESS] STORE MOVEMENTS',
  GET_FAIL: '[GET_FAIL] STORE MOVEMENTS',
  CLEAR_STORE_MOVEMENT_DATA: '[CLEAR_STORE_MOVEMENT_DATA] STORE MOVEMENTS',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/store-movement`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http('api/store-movement', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newMovement" />))
        history.push('/store-movements')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newMovementFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, movementId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/store-movement/${movementId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedMovement" />))
        history.push('/store-movements')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateMovementFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (itemId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: itemId })

    http(`api/store-movement/${itemId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearMovementData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_FAIL })
  }
}
