import { ActionTypes } from './actions'

const initialState = {
  contract_id: null,
  contractItems: [],
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
    user: {},
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  contractData: {},
  contractId: null,
  totalPrice: 0,
  totalQty: 0,
  contract_variants: [],

  // ** New contract
  defaultCode: null,
  customersSuppliers: [],
  unitMeasures: [],
  taxes: [],
  deducibilities: [],
  officesUnits: [],
  paymentMethods: [],
  types: [],
  items: [],
  financialItems: [],
  workers: [],
  selectedContract: {},
  performances: [],

  allVariants: [],
  itemVariantOptions: [],
  updatedCode: null,
  totalDue: null,
  totalDueNoVAT: null,
  selectedItems: [],
  selectedType: null,
  startDate: null,
  endDate: null,
  selectedOfficeUnits: [],
  itemsData: [],
}

export const contracts = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: payload,
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_INFO_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          info: payload,
        },
      }

    case ActionTypes.GET_ITEMS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          items: payload,
        },
      }

    case ActionTypes.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          payments: payload,
        },
      }

    case ActionTypes.GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          movements: payload,
        },
      }

    case ActionTypes.GET_PERFOMANCE_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          employees_performance: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.SET_CONTRACT_TYPE:
      return {
        ...state,
        contractType: payload,
      }

    case ActionTypes.SET_CONTRACT_DATA:
      return {
        ...state,
        contractData: { ...state.contractData, ...payload },
      }

    case ActionTypes.SET_CONTRACT_ITEM:
      return {
        ...state,
        contractItems: [...state.contractItems, payload],
      }

    case ActionTypes.SET_ITEMS:
      return {
        ...state,
        items: payload,
      }

    case ActionTypes.SET_VARIANTS:
      return {
        ...state,
        contract_variants: payload,
      }

    case ActionTypes.CLEAR_CONTRACT_ITEMS:
      return {
        ...state,
        contractItem: [],
      }

    case ActionTypes.SET_CONTRACT_ID:
      return {
        ...state,
        contractId: payload,
      }

    case ActionTypes.SET_TOTAL_PRICE:
      return {
        ...state,
        totalPrice: payload,
      }

    case ActionTypes.SET_TOTAL_QTY:
      return {
        ...state,
        totalQty: payload,
      }

    case ActionTypes.CLEAR_CONTRACT_DATA:
      return {
        ...state,
        contractData: {},
        contractId: null,
        totalPrice: 0,
        totalQty: 0,
        items: [],
        variants: [],
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    // ** New contract
    case ActionTypes.SET_DEFAULT_CODE:
      return {
        ...state,
        defaultCode: payload,
      }

    case ActionTypes.SET_CUSTOMERS_SUPPLIERS:
      return {
        ...state,
        customersSuppliers: payload,
      }

    case ActionTypes.SET_UNIT_MEASURES:
      return {
        ...state,
        unitMeasures: payload,
      }

    case ActionTypes.SET_TAXES:
      return {
        ...state,
        taxes: payload,
      }

    case ActionTypes.SET_DEDUCIBILITIES:
      return {
        ...state,
        deducibilities: payload,
      }

    case ActionTypes.SET_OFFICES_UNITS:
      return {
        ...state,
        officesUnits: payload,
      }

    case ActionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: payload,
      }

    case ActionTypes.SET_TYPES:
      return {
        ...state,
        types: payload,
      }
      
    case ActionTypes.SET_FINANCIAL_ITEMS:
      return {
        ...state,
        financialItems: payload,
      }

    case ActionTypes.SET_WORKERS:
      return {
        ...state,
        workers: payload,
      }

    case ActionTypes.SET_SELECTED_CONTRACT:
      return {
        ...state,
        selectedContract: payload,
      }

    case ActionTypes.SET_PERFORMANCES:
      return {
        ...state,
        performances: payload,
      }

    // ** Utils
    case ActionTypes.SET_ALL_VARIANTS:
      return {
        ...state,
        allVariants: payload,
      }

    case ActionTypes.SET_ITEM_VARIANT_OPTIONS:
      return {
        ...state,
        itemVariantOptions: payload,
      }

    case ActionTypes.UPDATE_CODE:
      return {
        ...state,
        updatedCode: payload,
      }

    case ActionTypes.UPDATE_TOTAL_DUE:
      return {
        ...state,
        totalDue: payload,
      }

    case ActionTypes.UPDATE_TOTAL_DUE_NO_VAT:
      return {
        ...state,
        totalDueNoVAT: payload,
      }

    case ActionTypes.UPDATE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: payload,
      }

    case ActionTypes.UPDATE_SELECTED_TYPE:
      return {
        ...state,
        selectedType: payload,
      }

    case ActionTypes.UPDATE_START_DATE:
      return {
        ...state,
        startDate: payload,
      }

    case ActionTypes.UPDATE_END_DATE:
      return {
        ...state,
        endDate: payload,
      }

    case ActionTypes.UPDATE_SELECTED_OFFICE_UNITS:
      return {
        ...state,
        selectedOfficeUnits: payload,
      }

    case ActionTypes.UPDATE_ITEMS_DATA:
      return {
        ...state,
        itemsData: payload,
      }

    case ActionTypes.CLEAR_DEFAULT_CODE:
      return {
        ...state,
        defaultCode: null,
      }

    case ActionTypes.CLEAR_CUSTOMERS_SUPPLIERS:
      return {
        ...state,
        customersSuppliers: [],
      }

    case ActionTypes.CLEAR_UNIT_MEASURES:
      return {
        ...state,
        unitMeasures: [],
      }

    case ActionTypes.CLEAR_TAXES:
      return {
        ...state,
        taxes: [],
      }

    case ActionTypes.CLEAR_DEDUCIBILITIES:
      return {
        ...state,
        deducibilities: [],
      }

    case ActionTypes.CLEAR_OFFICES_UNITS:
      return {
        ...state,
        officesUnits: [],
      }

    case ActionTypes.CLEAR_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: [],
      }

    case ActionTypes.CLEAR_TYPES:
      return {
        ...state,
        types: [],
      }

    case ActionTypes.CLEAR_ITEMS:
      return {
        ...state,
        items: [],
      }
      
    case ActionTypes.CLEAR_FINANCIAL_ITEMS:
      return {
        ...state,
        financialItems: [],
      }

    case ActionTypes.CLEAR_WORKERS:
      return {
        ...state,
        workers: [],
      }

    case ActionTypes.CLEAR_SELECTED_CONTRACT:
      return {
        ...state,
        selectedContract: {},
      }

    case ActionTypes.CLEAR_PERFORMANCES:
      return {
        ...state,
        performances: [],
      }

    case ActionTypes.CLEAR_ALL_VARIANTS:
      return {
        ...state,
        allVariants: [],
      }

    case ActionTypes.CLEAR_ITEM_VARIANT_OPTIONS:
      return {
        ...state,
        itemVariantOptions: [],
      }

    case ActionTypes.CLEAR_UPDATED_CODE:
      return {
        ...state,
        updatedCode: null,
      }

    case ActionTypes.CLEAR_TOTAL_DUE:
      return {
        ...state,
        totalDue: null,
      }

    case ActionTypes.CLEAR_TOTAL_DUE_NO_VAT:
      return {
        ...state,
        totalDueNoVAT: null,
      }

    case ActionTypes.CLEAR_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: [],
      }

    case ActionTypes.CLEAR_SELECTED_TYPE:
      return {
        ...state,
        selectedType: null,
      }

    case ActionTypes.CLEAR_START_DATE:
      return {
        ...state,
        startDate: null,
      }

    case ActionTypes.CLEAR_END_DATE:
      return {
        ...state,
        endDate: null,
      }

    case ActionTypes.CLEAR_SELECTED_OFFICE_UNITS:
      return {
        ...state,
        selectedOfficeUnits: [],
      }

    case ActionTypes.CLEAR_ITEMS_DATA:
      return {
        ...state,
        itemsData: [],
      }

    default:
      return state
  }
}
