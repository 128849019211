// ** Redux Imports
import { combineReducers } from 'redux'

//
import { auth } from '@store/auth/reducers'
import { navbar } from '@store/navbar/reducers'
import { layout } from '@store/layout/reducers'
import { register } from '@store/register/reducers'
//
import { user } from '@store/user/reducers'
import { itemCategories } from '@store/itemCategories/reducers'
import { itemAttributes } from '@store/itemAttributes/reducers'
import { storeMovements } from '@store/storeMovements/reducers'
import { itemVariants } from '@store/itemVariants/reducers'
import { priceLists } from '@store/priceLists/reducers'
import { employees } from '@store/employees/reducers'
import { invoices } from '@store/invoices/reducers'
import { asas } from '@store/asas/reducers'
import { roles } from '@store/roles/reducers'
import { items } from '@store/items/reducers'
import { offices } from '@store/offices/reducers'
import { employeeContracts } from '@store/employee-contracts/reducers'
import { contracts } from '@store/contracts/reducers'
import { simulations } from '@store/simulations/reducers'
import { contractPayments } from '@store/contract-payments/reducers'
import { workHours } from '@store/workHours/reducers'
import { companies } from '@store/companies/reducers'
import { permissions } from '@store/permissions/reducers'
import { payments } from '@store/payments/reducers'
import { legalNatures } from '@store/legalNatures/reducers'
import { customersSuppliers } from '@store/customersSuppliers/reducers'
import { officeAuthorization } from '@store/officeAuthorization/reducers'
import { companyAuthorization } from '@store/companyAuthorization/reducers'
import calendar from '@store/calendar/reducers'
import { budgets } from '@store/budgets/reducers'
import { workPhases } from './work-phases/reducers'
//import { workPhase } from '@store/workPhase/reducers'
import { notifications } from '@store/notifications/reducers'
import { movements } from './movements/reducers'

const appReducer = combineReducers({
  auth,
  notifications,
  navbar,
  layout,
  register,
  //
  user,
  employees,
  priceLists,
  invoices,
  calendar,
  payments,
  items,
  asas,
  roles,
  offices,
  employeeContracts,
  storeMovements,
  contracts,
  itemVariants,
  itemCategories,
  itemAttributes,
  contractPayments,
  movements,
  workPhases,
  //workPhase,
  workHours,
  companies,
  permissions,
  legalNatures,
  budgets,
  simulations,
  customersSuppliers,
  officeAuthorization,
  companyAuthorization,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    return appReducer(undefined, action)
  } else return appReducer(state, action)
}

export default rootReducer
