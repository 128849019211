import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  csRegistry: {},
  csAddress: {},

  // ** New CS
  legalEntities: [],
  countries: [],
  selectedCS: {},
  selectedCountry: {},
  isPhysicalPerson: false,
}

export const customersSuppliers = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }

    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.CLEAR_CS_DATA:
      return {
        ...state,
        get: {
          isLoading: false,
          error: null,
        },
      }

    case ActionTypes.SET_CS_REGISTRY:
      return {
        ...state,
        csRegistry: payload,
      }

    case ActionTypes.SET_CS_ADDRESS:
      return {
        ...state,
        csAddress: payload,
      }

    case ActionTypes.CLEAR_REGISTRY_ADDRESS:
      return {
        ...state,
        csAddress: {},
        csRegistry: {},
      }

    // ** New CS
    case ActionTypes.SET_LEGAL_ENTITIES:
      return {
        ...state,
        legalEntities: payload,
      }

    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      }

    case ActionTypes.CLEAR_LEGAL_ENTITIES:
      return {
        ...state,
        legalEntities: [],
      }

    case ActionTypes.CLEAR_COUNTRIES:
      return {
        ...state,
        countries: [],
      }

    case ActionTypes.GET_CS:
      return {
        ...state,
        selectedCS: payload,
      }

    case ActionTypes.CLEAR_GET_CS:
      return {
        ...state,
        selectedCS: {},
      }

    case ActionTypes.UPDATE_IS_PHYSICAL_PERSON:
      return {
        ...state,
        isPhysicalPerson: payload,
      }

    case ActionTypes.CLEAR_IS_PHYSICAL_PERSON:
      return {
        ...state,
        isPhysicalPerson: false,
      }

    case ActionTypes.UPDATE_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: payload,
      }
    
      case ActionTypes.CLEAR_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: {},
      }

    default:
      return state
  }
}
