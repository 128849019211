import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
    user: {},
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  contractData: {},

  // ** New worker contracts
  types: [],
  selectedContract: {},
  recessMotivations: [],
}

export const employeeContracts = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.SET_CONTRACT_TYPE:
      return {
        ...state,
        contractType: payload,
      }

    case ActionTypes.SET_CONTRACT_DATA:
      return {
        ...state,
        contractData: { ...state.contractData, ...payload },
      }

    case ActionTypes.CLEAR_CONTRACT_DATA:
      return {
        ...state,
        contractData: {},
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }


    // ** New worker contracts
    case ActionTypes.SET_TYPES:
      return {
        ...state,
        types: payload,
      }

    case ActionTypes.SET_SELECTED_CONTRACT:
      return {
        ...state,
        selectedContract: payload
      }

    case ActionTypes.SET_RECESS_MOTIVATIONS:
      return {
        ...state,
        recessMotivations: payload,
      }

    case ActionTypes.CLEAR_TYPES:
      return {
        ...state,
        types: [],
      }

    case ActionTypes.CLEAR_SELECTED_CONTRACT:
      return {
        ...state,
        selectedContract: {},
      }

    case ActionTypes.CLEAR_RECESS_MOTIVATIONS:
      return {
        ...state,
        recessMotivations: [],
      }

    case ActionTypes.CLEAR_WORKER_CONTRACTS:
      return {
        ...state,
        listing: {
          data: [],
          error: null,
          isLoading: false,
        },
      }

    default:
      return state
  }
}
