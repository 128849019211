// ** Handles Layout Content Width (full / boxed)
export const handleContentWidth = (value) => (dispatch) =>
  dispatch({ type: 'HANDLE_CONTENT_WIDTH', value })

// ** Handles Menu Collapsed State (Bool)
export const handleMenuCollapsed = (value) => (dispatch) =>
  dispatch({ type: 'HANDLE_MENU_COLLAPSED', value })

// ** Handles Menu Hidden State (Bool)
export const handleMenuHidden = (value) => (dispatch) =>
  dispatch({ type: 'HANDLE_MENU_HIDDEN', value })

// ** Handles RTL (Bool)
export const handleRTL = (value) => (dispatch) =>
  dispatch({ type: 'HANDLE_RTL', value })

export const showToast = (data, status = 'success') => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_TOAST', payload: { status, data } })
    setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST' })
    }, 1)
  }
}

/**
 * Use this code as example:
 * {
 * title = 'Alert!',
 * description = 'Are you sure?',
 * actions = [
 * {
 * label: 'Yes',
 * color: 'primary',
 * onClick: () => {
 * dispatch(closePrompt())
 * },
 *  }
 * ],
 * }
 */
export const showPrompt = (payload) => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_PROMPT', payload })
  }
}

export const hidePrompt = () => {
  return (dispatch) => {
    dispatch({ type: 'HIDE_PROMPT' })
  }
}
