import { ActionTypes } from './actions'

const initialState = {
  active: null,
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
    id: 0,
  },
  update: {
    isLoading: false,
    error: null,
  },
  companyRegistry: {},
  companyAddress: {},
  offices: [],

  // ** new companies
  selectedCompany: {},
  legalEntities: [],
  countries: [],
  selectedCountry: {},
  ivaRegimes: [],
  ivaLiquidations: [],
}

export const companies = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }
    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }
    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }
    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          id: 0,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }
    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }
    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.CLEAR_COMPANY_DATA:
      return {
        ...state,
        get: {
          isLoading: false,
          error: null,
        },
      }
    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }
    case ActionTypes.SET_COMPANY_REGISTRY:
      return {
        ...state,
        companyRegistry: payload,
      }
    case ActionTypes.SET_COMPANY_ADDRESS:
      return {
        ...state,
        companyAddress: payload,
      }
    case ActionTypes.CLEAR_REGISTRY_ADDRESS:
      return {
        ...state,
        companyAddress: {},
        companyRegistry: {},
      }

    case ActionTypes.SET_OFFICES:
      return {
        ...state,
        offices: payload,
      }

    // ** new Companies
    case ActionTypes.GET_COMPANY:
      return {
        ...state,
        selectedCompany: payload,
      }

    case ActionTypes.SET_LEGAL_ENITITES:
      return {
        ...state,
        legalEntities: payload,
      }

    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      }

    case ActionTypes.SET_IVA_REGIMES:
      return {
        ...state,
        ivaRegimes: payload,
      }

    case ActionTypes.SET_IVA_LIQUIDATIONS:
      return {
        ...state,
        ivaLiquidations: payload,
      }

    case ActionTypes.UPDATE_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: payload,
      }

    case ActionTypes.CLEAR_GET_COMPANY:
      return {
        ...state,
        selectedCompany: {},
      }

    case ActionTypes.CLEAR_LEGAL_ENITITES:
      return {
        ...state,
        legalEntities: [],
      }

    case ActionTypes.CLEAR_COUNTRIES:
      return {
        ...state,
        countries: [],
      }

    case ActionTypes.CLEAR_IVA_REGIMES:
      return {
        ...state,
        ivaRegimes: [],
      }

    case ActionTypes.CLEAR_IVA_LIQUIDATIONS:
      return {
        ...state,
        ivaLiquidations: [],
      }

    case ActionTypes.CLEAR_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: {},
      }

    default:
      return state
  }
}
