import useJwt from '@src/auth/jwt/useJwt'
import axios from 'axios'
import moment from 'moment'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { year: 'numeric', month: '2-digit', day: '2-digit' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateEU = (value, formatting = { year: 'numeric', month: '2-digit', day: '2-digit' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('fr-CA').format(new Date(value))
}

/**
 ** Return date difference in days  **
 * @param {String} start start date [1/12/2021])
 * @param {String} end end date
 */
export const dateDifference = (start, end) => {
  const m1 = moment(new Date(start))
  const m2 = moment(new Date(end))
  return m2.diff(m1, 'days')
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
})

export const addIndexToData = (data) => {
  return data.map((item, index) => ({...item, index}))
}

export const getHeaders = () => {
  const config = useJwt.jwtConfig
  const token = localStorage.getItem(config.storageTokenKeyName)
  return { Authorization: `Bearer ${token}` }
}

export const http = async (path = '/', { method = 'get', body, withoutAuth = false, serializeBody = false, getRawData = false } = {}) => {
  try {
    if (!path) {
      throw new Error('API path not defined!')
    }
    
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    
    if (!withoutAuth) {
      const config = useJwt.jwtConfig
      const token = localStorage.getItem(config.storageTokenKeyName)
      headers.Authorization = `Bearer ${token}`
    }
    
    let url = process.env.REACT_APP_API_ENDPOINT + (path[0] !== '/' ? `/${path}` : path)
    let data
    
    if (body && (method === 'get' || serializeBody)) {
      url +=
      '?' +
      Object.keys(body)
      .map((key) => key + '=' + body[key])
      .join('&')
    } else {
      data = { ...body }
    }
    const apiResponse = await axios({
      url,
      data,
      method,
      headers,
    })
    console.log('Url---->', url)
    console.log('Utils-http-apiResponse-->', apiResponse)
    if (!(apiResponse.status === 200 || apiResponse.status === 201)) {
      if (`${apiResponse.status}`.slice(0, 2) === '40') {
        alert('Authentication Error!')
      }
      throw new Error()
    }

    if (getRawData) {
      return apiResponse.data
    }

    const responseData = apiResponse.data
    if (!(apiResponse.status === 200 || apiResponse.status === 201)) {
      throw new Error(
        responseData.error
          ? JSON.stringify(responseData.error) // FIXME: either error or errors
          : responseData.message
      )
    }

    if (responseData.data) return responseData.data
    else return responseData
  } catch (error) {
    throw new Error(error?.message || 'Some unexpected error occurred! Please try again.')
  }
}
