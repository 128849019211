import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  SET_ACTIVE_OFFICE: '[SET_ACTIVE_OFFICE] Offices',
  LOAD_LIST_START: '[LOAD_LIST_START] Offices',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Offices',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Offices',
  //
  DELETE_START: '[DELETE_START] Offices',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Offices',
  DELETE_FAIL: '[DELETE_FAIL] Offices',
  //
  ADD_START: '[ADD_START] Offices',
  ADD_SUCCESS: '[ADD_SUCCESS] Offices',
  ADD_FAIL: '[ADD_FAIL] Offices',
  //
  UPDATE_START: '[UPDATE_START] Offices',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Offices',
  UPDATE_FAIL: '[UPDATE_FAIL] Offices',
  //
  GET_START: '[GET_START] Offices',
  GET_SUCCESS: '[GET_SUCCESS] Offices',
  GET_FAIL: '[GET_FAIL] Offices',
  //
  GET_WH_START: '[GET_WH_START] Offices',
  GET_WH_SUCCESS: '[GET_WH_SUCCESS] Offices',
  GET_WH_FAIL: '[GET_WH_FAIL] Offices',
  //
  CLEAR_OFFICE_DATA: '[CLEAR_OFFICE_DATA] Offices',

  // ** New office
  SET_COMPANY: '[SET_COMPANY] Offices',
  SET_COUNTRIES: '[SET_COUNTRIES] Offices',
  SET_UNITS: '[SET_UNITS] Offices',
  SET_SELECTED_OFFICE: '[SET_SELECTED_OFFICE] Offices',
  CLEAR_COMPANY: '[CLEAR_COMPANY] Offices',
  CLEAR_COUNTRIES: '[CLEAR_COUNTRIES] Offices',
  CLEAR_UNITS: '[CLEAR_UNITS] Offices',
  CLEAR_SELECTED_OFFICE: '[CLEAR_SELECTED_OFFICE] Offices',
}

export const getList = (activeCompany) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`/api/offices`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    http(`api/offices/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedOffice" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteOfficeFail" />), 'error')
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = (payload, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('/api/offices', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newOffice" />))
        history.push('/companies/offices')
        window.location.reload()
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newOfficeFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ id, data }, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: data })

    http(`api/offices/${id}`, { method: 'put', body: data })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedOffice" />))
        history.push('/companies/offices')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateOfficeFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: id })
    http(`api/offices/${id}`)
      .then((payload) => {
        dispatch({ type: ActionTypes.GET_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getWorkHours = ({ office_id, unit_id }) => {
  return (dispatch) => {
    http(`/api/offices/${office_id}/units/${unit_id}/opening-hours`)
      .then((payload) => {
        dispatch({ type: ActionTypes.GET_WH_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_WH_FAIL,
          payload: error.message,
        })
      })
  }
}

export const clearOfficeData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_OFFICE_DATA })
  }
}

export const clearWorkHourData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_WH_FAIL })
  }
}

// ** New office
export const addOffice = ({ payload, history }) => {
  return (dispatch) => {
    http('/api/offices', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newOffice" />))
        history.push('/companies/offices')
        window.location.reload()
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newOfficeFail" />, 'error'))
      })
  }
}

export const updateOffice = ({payload, officeId, history}) => {
  return dispatch => {
    http(`api/offices/${officeId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedOffice" />))
        history.push('/companies/offices')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateOfficeFail" />, 'error'))
      })
  }
}

export const setOfficeCreate = () => {
  return (dispatch) => {
    http('api/offices/create').then((response) => {
      const { company, countries, units } = response
      dispatch({ type: ActionTypes.SET_COMPANY, payload: company })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.SET_UNITS, payload: units })
    })
  }
}

export const setOfficeEdit = (id) => {
  return (dispatch) => {
    http(`api/offices/${id}/edit`).then((response) => {
      const { company, countries, office, units } = response
      dispatch({ type: ActionTypes.SET_COMPANY, payload: company })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.SET_UNITS, payload: units })
      dispatch({ type: ActionTypes.SET_SELECTED_OFFICE, payload: office })
    })
  }
}

export const clearOfficeCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_COMPANY })
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
    dispatch({ type: ActionTypes.CLEAR_UNITS })
  }
}

export const clearOfficeEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_COMPANY })
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
    dispatch({ type: ActionTypes.CLEAR_UNITS })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_OFFICE })
  }
}
