import { InlineToast } from '@src/views/components/InlineToast'
import { showToast, hidePrompt } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Contracts',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Contracts',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Contracts',
  //
  DELETE_START: '[DELETE_START] Contracts',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Contracts',
  DELETE_FAIL: '[DELETE_FAIL] Contracts',
  //
  ADD_START: '[ADD_START] Contracts',
  ADD_SUCCESS: '[ADD_SUCCESS] Contracts',
  ADD_FAIL: '[ADD_FAIL] Contracts',
  //
  UPDATE_START: '[UPDATE_START] Contracts',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Contracts',
  UPDATE_FAIL: '[UPDATE_FAIL] Contracts',
  //
  GET_START: '[GET_START] Contracts',
  GET_SUCCESS: '[GET_SUCCESS] Contracts',
  GET_FAIL: '[GET_FAIL] Contracts',
  GET_INFO_SUCCESS: '[GET_INFO_SUCCESS] Contracts',
  GET_INFO_FAIL: '[GET_INFO_FAIL] Contracts',
  GET_ITEMS_SUCCESS: '[GET_ITEMS_SUCCESS] Contracts',
  GET_ITEMS_FAIL: '[GET_ITEMS_FAIL] Contracts',
  GET_PAYMENTS_SUCCESS: '[GET_PAYMENTS_SUCCESS] Contracts',
  GET_PAYMENTS_FAIL: '[GET_PAYMENTS_FAIL] Contracts',
  GET_MOVEMENTS_SUCCESS: '[GET_MOVEMENTS_SUCCESS] Contracts',
  GET_MOVEMENTS_FAIL: '[GET_MOVEMENTS_FAIL] Contracts',
  GET_PERFOMANCE_SUCCESS: '[GET_PERFOMANCE_SUCCESS] Contracts',
  GET_PERFOMANCE_FAIL: '[GET_PERFOMANCE_FAIL] Contracts',
  CLEAR_CONTRACT_DATA: '[CLEAR_CONTRACT_DATA] Contracts',
  SET_CONTRACT_DATA: '[SET_CONTRACT_DATA] Contracts',
  SET_CONTRACT_ITEM: '[SET_CONTRACT_ITEM] Contracts',
  CLEAR_CONTRACT_ITEMS: '[CLEAR_CONTRACT_ITEMS] Contracts',
  SET_CONTRACT_ID: '[SET_CONTRACT_ID] Contracts',
  SET_TOTAL_PRICE: '[SET_TOTAL_PRICE] Contracts',
  SET_ITEMS: '[SET_ITEMS] Contracts',
  SET_VARIANTS: '[SET_VARIANTS] Contracts',
  SET_TOTAL_QTY: '[SET_TOTAL_QTY] Contracts',

  // ** New contract
  SET_DEFAULT_CODE: '[SET_DEFAULT_CODE] Contracts',
  SET_CUSTOMERS_SUPPLIERS: '[SET_CUSTOMERS_SUPPLIERS] Contracts',
  SET_UNIT_MEASURES: '[SET_UNIT_MEASURES] Contracts',
  SET_TAXES: '[SET_TAXES] Contracts',
  SET_DEDUCIBILITIES: '[SET_DEDUCIBILITIES] Contracts',
  SET_OFFICES_UNITS: '[SET_OFFICES_UNITS] Contracts',
  SET_PAYMENT_METHODS: '[SET_PAYMENT_METHODS] Contracts',
  SET_PERFORMANCES: '[SET_PERFORMANCES] Contracts',
  SET_TYPES: '[SET_TYPES] Contracts',
  SET_FINANCIAL_ITEMS: '[SET_FINANCIAL_ITEMS] Contracts',
  SET_WORKERS: '[SET_WORKERS] Contracts',
  SET_SELECTED_CONTRACT: '[SET_SELECTED_CONTRACT] Contracts',

  // ** Utils
  SET_ALL_VARIANTS: '[SET_ALL_VARIANTS] Contracts',
  SET_ITEM_VARIANT_OPTIONS: '[SET_ITEM_VARIANT_OPTIONS] Contracts',
  UPDATE_CODE: '[UPDATE_CODE] Contracts',
  UPDATE_TOTAL_DUE: '[UPDATE_TOTAL_DUE] Contracts',
  UPDATE_TOTAL_DUE_NO_VAT: '[UPDATE_TOTAL_DUE_NO_VAT] Contracts',
  UPDATE_SELECTED_ITEMS: '[UPDATE_SELECTED_ITEMS] Contracts',
  UPDATE_SELECTED_TYPE: '[UPDATE_SELECTED_TYPE] Contracts',
  UPDATE_START_DATE: '[UPDATE_START_DATE] Contracts',
  UPDATE_END_DATE: '[UPDATE_END_DATE] Contracts',
  UPDATE_SELECTED_OFFICE_UNITS: '[UPDATE_SELECTED_OFFICE_UNITS] Contracts',
  UPDATE_ITEMS_DATA: '[UPDATE_ITEMS_DATA] Contracts',

  CLEAR_ALL_VARIANTS: '[CLEAR_ALL_VARIANTS] Contracts',
  CLEAR_ITEM_VARIANT_OPTIONS: '[CLEAR_ITEM_VARIANT_OPTIONS] Contracts',
  CLEAR_DEFAULT_CODE: '[CLEAR_DEFAULT_CODE] Contracts',
  CLEAR_CUSTOMERS_SUPPLIERS: '[CLEAR_CUSTOMERS_SUPPLIERS] Contracts',
  CLEAR_UNIT_MEASURES: '[CLEAR_UNIT_MEASURES] Contracts',
  CLEAR_TAXES: '[CLEAR_TAXES] Contracts',
  CLEAR_DEDUCIBILITIES: '[CLEAR_DEDUCIBILITIES] Contracts',
  CLEAR_OFFICES_UNITS: '[CLEAR_OFFICES_UNITS] Contracts',
  CLEAR_PAYMENT_METHODS: '[CLEAR_PAYMENT_METHODS] Contracts',
  CLEAR_PERFORMANCES: '[CLEAR_PERFORMANCES] Contracts',
  CLEAR_TYPES: '[CLEAR_TYPES] Contracts',
  CLEAR_ITEMS: '[CLEAR_ITEMS] Contracts',
  CLEAR_FINANCIAL_ITEMS: '[CLEAR_FINANCIAL_ITEMS] Contracts',
  CLEAR_WORKERS: '[CLEAR_WORKERS] Contracts',
  CLEAR_SELECTED_CONTRACT: '[CLEAR_SELECTED_CONTRACT] Contracts',

  CLEAR_UPDATED_CODE: '[CLEAR_UPDATED_CODE] Contracts',
  CLEAR_TOTAL_DUE: '[CLEAR_TOTAL_DUE] Contracts',
  CLEAR_TOTAL_DUE_NO_VAT: '[CLEAR_TOTAL_DUE_NO_VAT] Contracts',
  CLEAR_SELECTED_ITEMS: '[CLEAR_SELECTED_ITEMS] Contracts',
  CLEAR_SELECTED_TYPE: '[CLEAR_SELECTED_TYPE] Contracts',
  CLEAR_START_DATE: '[CLEAR_START_DATE] Contracts',
  CLEAR_END_DATE: '[CLEAR_END_DATE] Contracts',
  CLEAR_SELECTED_OFFICE_UNITS: '[CLEAR_SELECTED_OFFICE_UNITS] Contracts',
  CLEAR_ITEMS_DATA: '[CLEAR_ITEMS_DATA] Contracts',
}

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`/api/contracts?result_type=all`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getBudgetContractsList = (budget_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`api/budgets/${budget_id}/contracts`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getSimulationContractsList = (simulation_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`/api/simulation/${simulation_id}/contract`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const addEmployeePerformance = ({ payload, history, budget_id, simulation_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`api/contract-item-variant-employee`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="performanceAdded" />))
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
        if (budget_id) history.push(`/budgets/${budget_id}/budget-contracts/`)
        else if (simulation_id) history.push(`/simulations/${simulation_id}/simulation-contracts/`)
        else history.push(`/contracts`)
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="performanceAddFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addContractInfo = ({ payload }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`api/contracts`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addContractItem = ({ payload }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`/api/item-variant`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newContractItem" />))
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateContractInfo = ({ contract_id, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/contracts/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.push(`/contracts`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateBudgetContractInfo = ({ contract_id, payload, history, budget_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/budgets/${budget_id}/contract/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.push(`/budgets/${budget_id}/budget-contracts/`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateSimulationContractInfo = ({ contract_id, payload, history, simulation_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`/api/simulation/${simulation_id}/contract/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.push(`/simulations/${simulation_id}/simulation-contracts/`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateContractItem = ({ contract_id, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/contract-item-variant/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        history.push('/contracts')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const terminateContract = ({ contract_id, payload }) => {
  return (dispatch) => {
    http(`api/workers/${payload.employee_id}/contracts/${contract_id}/terminate`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(hidePrompt())
        dispatch(showToast(<InlineToast title="Success" description="contractTerminated" />))
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="contractTerminateFail" />, 'error'))
      })
  }
}

export const getContractInfo = (contract_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`api/contracts/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_INFO_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getContractItems = (contract_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`api/contracts/${contract_id}/item-variant`)
      .then((payload) => dispatch({ type: ActionTypes.GET_ITEMS_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getContractPayments = (contract_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`api/contract-payment/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_PAYMENTS_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getContractMovements = (contract_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`api/contract-store-movements/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_MOVEMENTS_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getContractEmployees = (contract_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: contract_id })
    http(`api/contract-item-variant-employee/${contract_id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_PERFOMANCE_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const updateContractPayment = ({ contract_id, payload, history, budget_id, simulation_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/contract-payment/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        if (budget_id) history.push(`/budgets/${budget_id}/budget-contracts/`)
        else if (simulation_id) history.push(`/simulations/${simulation_id}/simulation-contracts/`)
        else history.push(`/contracts`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateContractMovement = ({ contract_id, payload, history, budget_id, simulation_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/contract-store-movements/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        if (budget_id) history.push(`/budgets/${budget_id}/budget-contracts/`)
        else if (simulation_id) history.push(`/simulations/${simulation_id}/simulation-contracts/`)
        else history.push(`/contracts`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateContractEmployee = ({ contract_id, payload, history, budget_id, simulation_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/contract-item-variant-employee/${contract_id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedContract" />))
        if (budget_id) history.push(`/budgets/${budget_id}/budget-contracts/`)
        else if (simulation_id) history.push(`/simulations/${simulation_id}/simulation-contracts/`)
        else history.push(`/contracts`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const setContractData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_CONTRACT_DATA, payload })
  }
}

export const setTotalPrice = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_TOTAL_PRICE, payload })
  }
}

export const setTotalQty = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_TOTAL_QTY, payload })
  }
}

export const setItems = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ITEMS, payload })
  }
}

export const setContractVariants = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_VARIANTS, payload })
  }
}

export const setId = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_CONTRACT_ID, payload })
  }
}

export const clearContractItems = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_ITEMS })
  }
}

export const clearContractData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_DATA })
  }
}

export const deleteBudgetContract = ({ contractId, payload, budget_id }) => {
  return (dispatch) => {
    http(`api/budgets/${budget_id}/contract/${contractId}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload })
        dispatch(showToast(<InlineToast title="Success" description="deletedContract" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteContractFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const deleteSimulationContract = ({ contractId, payload, simulation_id }) => {
  return (dispatch) => {
    http(`api/simulation/${simulation_id}/contract/${contractId}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload })
        dispatch(showToast(<InlineToast title="Success" description="Contract deleted!" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="Couldn't delete Contract!" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

// ** New contract
export const addContract = ({ payload, history }) => {
  return (dispatch) => {
    http('api/contracts', { method: 'POST', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.push('/contracts')
      })
      .catch(() => {
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, 'error'))
      })
  }
}

export const updateContract = ({ payload, contractId, history, path = '/contracts' }) => {
  return (dispatch) => {
    http(`api/contracts/${contractId}`, { method: 'PUT', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="updateContract" />))
        history.push(path)
      })
      .catch(() => {
        dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, 'error'))
      })
  }
}

export const deleteContract = (id) => {
  return (dispatch) =>
    http(`api/contracts/${id}`, { method: 'DELETE' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="contractDeleteSuccess" />))
      })
      .catch((error) => dispatch(showToast(<InlineToast title="Error" description="contractDeleteFail" />, "error")))
}

export const setContractCreate = () => {
  return (dispatch) => {
    http('api/contracts/create').then((response) => {
      const { code, ...payload } = response
      dispatch(setContractCreateUtils(payload))
      dispatch(setContractCode(code))
    })
  }
}

export const setContractCode = (code) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_DEFAULT_CODE, payload: code })
    dispatch({ type: ActionTypes.UPDATE_CODE, payload: code })
  }
}

export const setContractCreateUtils = (payload) => {
  return (dispatch) => {
    const { customers_suppliers, deducibilities, items, financial_items, offices_units, payment_methods, taxes, types, unit_measures, workers } =
      payload

    dispatch({ type: ActionTypes.SET_CUSTOMERS_SUPPLIERS, payload: customers_suppliers })
    dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: unit_measures })
    dispatch({ type: ActionTypes.SET_TAXES, payload: taxes })
    dispatch({ type: ActionTypes.SET_DEDUCIBILITIES, payload: deducibilities })
    dispatch({ type: ActionTypes.SET_OFFICES_UNITS, payload: offices_units })
    dispatch({ type: ActionTypes.SET_PAYMENT_METHODS, payload: payment_methods })
    dispatch({ type: ActionTypes.SET_TYPES, payload: types })
    dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
    dispatch({ type: ActionTypes.SET_FINANCIAL_ITEMS, payload: financial_items })
    dispatch({ type: ActionTypes.SET_WORKERS, payload: workers })
    dispatch({ type: ActionTypes.UPDATE_START_DATE, payload: new Date().toISOString().split("T")[0]})
  }
}

export const setContractEdit = (contractId) => {
  return (dispatch) => {
    http(`api/contracts/${contractId}/edit`).then((response) => {
      const {
        contract,
        customers_suppliers,
        deducibilities,
        items,
        financial_items,
        offices_units,
        payment_methods,
        performances,
        taxes,
        types,
        unit_measures,
        workers,
      } = response

      dispatch({ type: ActionTypes.SET_CUSTOMERS_SUPPLIERS, payload: customers_suppliers })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: unit_measures })
      dispatch({ type: ActionTypes.SET_TAXES, payload: taxes })
      dispatch({ type: ActionTypes.SET_DEDUCIBILITIES, payload: deducibilities })
      dispatch({ type: ActionTypes.SET_OFFICES_UNITS, payload: offices_units })
      dispatch({ type: ActionTypes.SET_PAYMENT_METHODS, payload: payment_methods })
      dispatch({ type: ActionTypes.SET_PERFORMANCES, payload: performances })
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_FINANCIAL_ITEMS, payload: financial_items })
      dispatch({ type: ActionTypes.SET_WORKERS, payload: workers })

      dispatch({ type: ActionTypes.SET_SELECTED_CONTRACT, payload: contract })
    })
  }
}

export const setItemUtils = ({ allVariants, itemVariantOptions }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ALL_VARIANTS, payload: allVariants })
    dispatch({ type: ActionTypes.SET_ITEM_VARIANT_OPTIONS, payload: itemVariantOptions })
  }
}

export const updateContractCode = (code) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_CODE, payload: code })
  }
}

export const updateTotalDue = (total) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_TOTAL_DUE, payload: total })
  }
}

export const updateTotalDueNoVAT = (total) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_TOTAL_DUE_NO_VAT, payload: total })
  }
}

export const updateContractSelectedItems = (items) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SELECTED_ITEMS, payload: items })
  }
}

export const updateContractType = (type) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SELECTED_TYPE, payload: type })
  }
}

export const updateContractStartDate = (date) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START_DATE, payload: date })
  }
}

export const updateContractEndDate = (date) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_END_DATE, payload: date })
  }
}

export const updateContractOfficeUnits = (units) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SELECTED_OFFICE_UNITS, payload: units })
  }
}

export const updateContractItemsData = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_ITEMS_DATA, payload: data })
  }
}

export const clearContractCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_DEFAULT_CODE })
    dispatch({ type: ActionTypes.CLEAR_CUSTOMERS_SUPPLIERS })
    dispatch({ type: ActionTypes.CLEAR_UNIT_MEASURES })
    dispatch({ type: ActionTypes.CLEAR_TAXES })
    dispatch({ type: ActionTypes.CLEAR_DEDUCIBILITIES })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
    dispatch({ type: ActionTypes.CLEAR_PAYMENT_METHODS })
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_FINANCIAL_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_WORKERS })
  }
}

export const clearContractEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CUSTOMERS_SUPPLIERS })
    dispatch({ type: ActionTypes.CLEAR_UNIT_MEASURES })
    dispatch({ type: ActionTypes.CLEAR_TAXES })
    dispatch({ type: ActionTypes.CLEAR_DEDUCIBILITIES })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
    dispatch({ type: ActionTypes.CLEAR_PAYMENT_METHODS })
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_FINANCIAL_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_WORKERS })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_CONTRACT })
  }
}

export const clearContractUtils = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ALL_VARIANTS })
    dispatch({ type: ActionTypes.CLEAR_ITEM_VARIANT_OPTIONS })
    dispatch({ type: ActionTypes.CLEAR_UPDATED_CODE })
    dispatch({ type: ActionTypes.CLEAR_TOTAL_DUE })
    dispatch({ type: ActionTypes.CLEAR_TOTAL_DUE_NO_VAT })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_TYPE })
    dispatch({ type: ActionTypes.CLEAR_START_DATE })
    dispatch({ type: ActionTypes.CLEAR_END_DATE })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_OFFICE_UNITS })
    dispatch({ type: ActionTypes.CLEAR_ITEMS_DATA })
  }
}
