import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Items',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Items',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Items',
  //
  DELETE_START: '[DELETE_START] Items',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Items',
  DELETE_FAIL: '[DELETE_FAIL] Items',
  //
  ADD_START: '[ADD_START] Items',
  ADD_SUCCESS: '[ADD_SUCCESS] Items',
  ADD_FAIL: '[ADD_FAIL] Items',
  //
  UPDATE_START: '[UPDATE_START] Items',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Items',
  UPDATE_FAIL: '[UPDATE_FAIL] Items',
  //
  GET_START: '[GET_START] Items',
  GET_SUCCESS: '[GET_SUCCESS] Items',
  GET_FAIL: '[GET_FAIL] Items',
  SET_ITEM_DATA: '[SET_ITEM_DATA] Items',

  CLEAR_ITEM_DATA: '[CLEAR_ITEM_DATA] Items',
  CLEAR_CONTRACT_ITEMS: '[CLEAR_CONTRACT_ITEMS] Items',

  // ** New item
  SET_ITEM_EDIT: '[GET_ITEM_EDIT] Items',
  UPDATE_ITEM_CODE: '[UPDATE_ITEM_CODE] Items',
  UPDATE_ITEM_TYPE: '[UPDATE_ITEM_TYPE] Items',
  SET_SELECTED_ATTRIBUTES: '[SET_SELECTED_ATTRIBUTES] Items',
  SET_UNIT_MEASURE: '[SET_UNIT_MEASURE] Items',
  SET_DEDUCIBILITIES: '[SET_DEDUCIBILITIES] Items ',
  SET_TAXES: '[SET_TAXES] Items',
  SET_ITEM_CREATE: '[SET_ITEM_CREATE] Items',
  SET_ATTRIBUTES: '[SET_ATTRIBUTES] Items',
  CLEAR_ATTRIBUTES: '[CLEAR_ATTRIBUTES] Items',
  CLEAR_SELECTED_ATTRIBUTES: '[CLEAR_SELECTED_ATTRIBUTES] Items',
  CLEAR_ITEM_CREATE: '[CLEAR_ITEM_CREATE] Items',
  CLEAR_ITEM_EDIT: '[CLEAR_ITEM_EDIT] Items',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`/api/items`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getFinancialList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(`/api/items?type=financial`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getUnitMeasures = (companyId) => {
  return (dispatch) => {
    http(`/api/unit-measures`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.SET_UNIT_MEASURE,
          payload,
        })
      )
      .catch((error) => {
        console.log(error)
      })
  }
}

export const getDeducibilities = (companyId) => {
  return (dispatch) => {
    http(`/api/deducibilities`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.SET_DEDUCIBILITIES,
          payload,
        })
      )
      .catch((error) => {
        console.log(error)
      })
  }
}

export const getTaxes = (companyId) => {
  return (dispatch) => {
    http(`/api/taxes`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.SET_TAXES,
          payload,
        })
      )
      .catch((error) => {
        console.log(error)
      })
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    http(`api/items/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: id })
        dispatch(showToast(<InlineToast title="Success" description="deletedItem" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteItemFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('api/items', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newItem" />))
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
        history.push('/items')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newItemFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, itemId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/items/${itemId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedItem" />))
        history.push('/items')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateItemFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (itemId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: itemId })

    http(`api/items/${itemId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getItemEdit = (id) => {
  return (dispatch) => {
    http(`api/items/${id}/edit`).then((response) => {
      const { asset_types, attributes, categories, code, cost_types, deducibilities, item, taxes, types, unit_measures } = response
      dispatch({ type: ActionTypes.SET_DEDUCIBILITIES, payload: deducibilities })
      dispatch({ type: ActionTypes.SET_TAXES, payload: taxes })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURE, payload: unit_measures })
      dispatch({ type: ActionTypes.SET_ITEM_CREATE, payload: { asset_types, categories, code, cost_types, types } })
      dispatch({ type: ActionTypes.SET_ATTRIBUTES, payload: attributes })
      dispatch({ type: ActionTypes.SET_ITEM_EDIT, payload: item })
    })
  }
}

export const setItemData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ITEM_DATA, payload })
  }
}

export const setItemCreate = () => {
  return (dispatch) => {
    http('api/items/create').then((response) => {
      const { asset_types, attributes, categories, code, cost_types, deducibilities, taxes, types, unit_measures } = response
      dispatch({ type: ActionTypes.SET_DEDUCIBILITIES, payload: deducibilities })
      dispatch({ type: ActionTypes.SET_TAXES, payload: taxes })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURE, payload: unit_measures })
      dispatch({ type: ActionTypes.SET_ITEM_CREATE, payload: { asset_types, categories, code, cost_types, types } })
      dispatch({ type: ActionTypes.SET_ATTRIBUTES, payload: attributes })
    })
  }
}

export const setSelectedAttributes = (attributes) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_SELECTED_ATTRIBUTES, payload: attributes })
  }
}

export const clearAttributes = () => {
  return dispatch => {
    dispatch({type: ActionTypes.CLEAR_ATTRIBUTES})
  }
}

export const clearSelectedAttributes = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_ATTRIBUTES })
  }
}

export const updateItemCode = (code) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_ITEM_CODE, payload: code })
  }
}

export const upadteItemType = type => {
  return dispatch => {
    dispatch({type: ActionTypes.UPDATE_ITEM_TYPE, payload: type})
  }
}

export const clearItemCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ITEM_CREATE })
  }
}

export const clearItemEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ITEM_EDIT })
  }
}

export const clearItemData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ITEM_DATA })
  }
}

export const clearItemGetData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_FAIL })
  }
}

export const clearContractItem = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_ITEMS })
  }
}

export const clearListing = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_FAIL })
  }
}
