import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  ADD_WORK_PHASE: '[ADD_WORK_PHASE] WorkPhase',
  SET_WORK_PHASES: '[SET_WORK_PHASES] WorkPhase',
  SET_ITEMS: '[SET_ITEMS] WorkPhase',
  SET_UNIT_MEASURES: '[SET_UNIT_MEASURES] WorkPhase',
  CLEAR_WORK_PHASES: '[CLEAR_WORK_PHASES] WorkPhase',
  CLEAR_ITEMS: '[CLEAR_ITEMS] WorkPhase',
  CLEAR_UNIT_MEASURES: '[CLEAR_UNIT_MEASURES] WorkPhase',
}

export const addWorkPhase = ({workPhase, variantId}) => {
  return (dispatch) => {
    http(`api/variants/${variantId}/material-bills`, { method: 'POST', body: workPhase })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="newWorkPhaseSuccess" />))
      })
      .catch((error) => dispatch(showToast(<InlineToast title="Error" description="newWorkPhaseError" />, error)))
  }
}

export const updateWorkPhase = ({workPhase, variantId, history}) => {
  return (dispatch) => {
    http(`api/variants/${variantId}/material-bills`, { method: 'POST', body: workPhase })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="workPhaseUpdate" />))
        history.push("/items")
      })
      .catch((error) => dispatch(showToast(<InlineToast title="Error" description="workPhaseUpdateError" />, error)))
  }
}

export const setWorkPhases = (variantId) => {
  return (dispatch) => {
    http(`api/variants/${variantId}/material-bills`).then((response) => {
      const { items, work_phases, unit_measures } = response
      dispatch({ type: ActionTypes.SET_WORK_PHASES, payload: work_phases })
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: unit_measures })
    })
  }
}

export const setItems = () => {
  return (dispatch) => {
    http('api/items').then((response) => {
      dispatch({ type: ActionTypes.SET_ITEMS, payload: response })
    })
  }
}

export const setUnitMeasures = () => {
  return (dispatch) => {
    http('api/unit-measures').then((response) => {
      dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: response })
    })
  }
}

export const clearWorkPhases = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_WORK_PHASES })
  }
}

export const clearItems = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
  }
}

export const clearUnitMeasures = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_UNIT_MEASURES })
  }
}
