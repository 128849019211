import { ActionTypes } from './actions'

const initialState = {
  workPhases: [],
  items: [],
  variants: [],
  unitMeasures: [],
}

export const workPhases = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_WORK_PHASES:
      return {
        ...state,
        workPhases: payload,
      }

    case ActionTypes.SET_ITEMS:
      return {
        ...state,
        items: payload,
      }

    case ActionTypes.SET_UNIT_MEASURES:
      return {
        ...state,
        unitMeasures: payload,
      }

    case ActionTypes.CLEAR_WORK_PHASES:
      return {
        ...state,
        workPhases: [],
      }

    case ActionTypes.CLEAR_ITEMS:
      return {
        ...state,
        items: [],
      }

    case ActionTypes.CLEAR_UNIT_MEASURES:
      return {
        ...state,
        unitMeasures: [],
      }

    default:
      return state
  }
}
