import { ActionTypes } from "./actions"

const initialState = {
    types: [],
    items: [],
    contracts: [],
    unitMeasures: [],
    officesUnits: [],
    selectedMovement: {},
}

export const movements = (state = initialState, { payload, type }) => {
    switch (type) {
        case ActionTypes.SET_TYPES:
            return {
                ...state,
                types: payload,
            }
            
        case ActionTypes.SET_ITEMS:
            return {
                ...state,
                items: payload,
            }

        case ActionTypes.SET_CONTRACTS:
            return {
                ...state,
                contracts: payload,
            }

        case ActionTypes.SET_UNIT_MEASURES:
            return {
                ...state,
                unitMeasures: payload,
            }

        case ActionTypes.SET_OFFICES_UNITS:
            return {
                ...state,
                officesUnits: payload,
            }

        case ActionTypes.SET_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement: payload,
            }
            
        case ActionTypes.CLEAR_TYPES:
            return {
                ...state,
                types: [],
            }
            
        case ActionTypes.CLEAR_ITEMS:
            return {
                ...state,
                items: [],
            }

        case ActionTypes.CLEAR_CONTRACTS:
            return {
                ...state,
                contracts: [],
            }

        case ActionTypes.CLEAR_UNIT_MEASURES:
            return {
                ...state,
                unitMeasures: [],
            }

        case ActionTypes.CLEAR_OFFICES_UNITS:
            return {
                ...state,
                officesUnits: [],
            }

        case ActionTypes.CLEAR_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement: {},
            }

        default: 
            return state
    }
}