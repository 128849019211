import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
  },
  getWorkHours: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },

  // ** New office
  company: {},
  countries: [],
  units: [],
  selectedOffice: {},
}

export const offices = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE_OFFICE:
      return {
        ...state,
        active: payload,
      }
    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.GET_WH_START:
      return {
        ...state,
        getWorkHours: {
          ...state.getWorkHours,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_WH_SUCCESS:
      return {
        ...state,
        getWorkHours: {
          ...state.getWorkHours,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_WH_FAIL:
      return {
        ...state,
        getWorkHours: {
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.CLEAR_OFFICE_DATA:
      return {
        ...state,
        get: {
          isLoading: false,
          error: payload,
        },
      }

    // ** New office
    case ActionTypes.SET_COMPANY:
      return {
        ...state,
        company: payload,
      }

    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      }

    case ActionTypes.SET_UNITS:
      return {
        ...state,
        units: payload,
      }

    case ActionTypes.SET_SELECTED_OFFICE:
      return {
        ...state,
        selectedOffice: payload,
      }

    case ActionTypes.CLEAR_COMPANY:
      return {
        ...state,
        company: {},
      }

    case ActionTypes.CLEAR_COUNTRIES:
      return {
        ...state,
        countries: [],
      }

    case ActionTypes.CLEAR_UNITS:
      return {
        ...state,
        units: [],
      }

    case ActionTypes.CLEAR_SELECTED_OFFICE:
      return {
        ...state,
        selectedOffice: {},
      }

    default:
      return state
  }
}
