// **  Initial State
const initialState = {
  message: '',
  userData: {
    id: 0,
  },
}

export const register = (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER':
      return {
        ...state,
        message: action.data.message,
        userData: action.data.data,
      }

    case 'UPDATE_DATA':
      return {
        ...state,
        userData: action.data.userData,
      }

    case 'REGISTER_CLEAR':
      return {
        ...state,
        message: null,
      }
    default:
      return state
  }
}
