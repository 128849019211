import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  SET_FORM_DATA: '[SET_FORM_DATA] Roles',
  //
  LOAD_LIST_START: '[LOAD_LIST_START] Roles',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Roles',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Roles',
  //
  DELETE_START: '[DELETE_START] Roles',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Roles',
  DELETE_FAIL: '[DELETE_FAIL] Roles',
  //
  ADD_START: '[ADD_START] Roles',
  ADD_SUCCESS: '[ADD_SUCCESS] Roles',
  ADD_FAIL: '[ADD_FAIL] Roles',
  //
  UPDATE_START: '[UPDATE_START] Roles',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Roles',
  UPDATE_FAIL: '[UPDATE_FAIL] Roles',
  //
  GET_START: '[GET_START] Roles',
  GET_SUCCESS: '[GET_SUCCESS] Roles',
  GET_FAIL: '[GET_FAIL] Roles',
  //
  CLEAR_ROLES: '[CLEAR_ROLES] Roles',
}

export const getList = (companyId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/companies/${companyId}/roles`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index, companyId }) => {
  return (dispatch) => {
    http(`api/companies/${companyId}/roles/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedRole" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteRoleFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = ({ payload, company_id, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`/api/companies/${company_id}/roles`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newRole" />))
        history.push(`/companies/${payload.company_id}/roles`)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newRoleFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ id, payload, company_id }, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload })
    http(`api/companies/${company_id}/roles/${id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedRole" />))
        history.push(`/companies/${company_id}/roles`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateRoleFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = ({ companyId, id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: id })

    http(`api/companies/${companyId}/roles/${id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setFormData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_FORM_DATA, payload })
  }
}

export const clearRoles = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ROLES })
  }
}
