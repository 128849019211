import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] ATTRIBUTES',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] ATTRIBUTES',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] ATTRIBUTES',
  //
  DELETE_START: '[DELETE_START] ATTRIBUTES',
  DELETE_SUCCESS: '[DELETE_SUCCESS] ATTRIBUTES',
  DELETE_FAIL: '[DELETE_FAIL] ATTRIBUTES',
  //
  ADD_START: '[ADD_START] ATTRIBUTES',
  ADD_SUCCESS: '[ADD_SUCCESS] ATTRIBUTES',
  ADD_FAIL: '[ADD_FAIL] ATTRIBUTES',
  //
  UPDATE_START: '[UPDATE_START] ATTRIBUTES',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] ATTRIBUTES',
  UPDATE_FAIL: '[UPDATE_FAIL] ATTRIBUTES',
  //
  GET_START: '[GET_START] ATTRIBUTES',
  GET_SUCCESS: '[GET_SUCCESS] ATTRIBUTES',
  GET_FAIL: '[GET_FAIL] ATTRIBUTES',
  CLEAR_ATTRIBUTE_DATA: '[CLEAR_ATTRIBUTE_DATA] ATTRIBUTES',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/attributes`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_START })

    http(`api/attributes/${id}`, { method: 'delete' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="deletedAttribute" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS, payload: index })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteAttributeFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = (payload, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('api/attributes', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newAttribute" />))
        history.push('/item-attributes')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newAttributeFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, attributeId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/attributes/${attributeId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedAttribute" />))
        history.push('/item-attributes')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateAttributeFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (itemId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: itemId })

    http(`api/attributes/${itemId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearAttributeData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ATTRIBUTE_DATA })
  }
}
