import { InlineToast } from '@src/views/components/InlineToast'
import { setContractCode, setContractCreateUtils } from '@store/contracts/actions'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] BUDGETS',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] BUDGETS',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] BUDGETS',
  //
  DELETE_START: '[DELETE_START] BUDGETS',
  DELETE_SUCCESS: '[DELETE_SUCCESS] BUDGETS',
  DELETE_FAIL: '[DELETE_FAIL] BUDGETS',
  //
  ADD_START: '[ADD_START] BUDGETS',
  ADD_SUCCESS: '[ADD_SUCCESS] BUDGETS',
  ADD_FAIL: '[ADD_FAIL] BUDGETS',
  //
  UPDATE_START: '[UPDATE_START] BUDGETS',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] BUDGETS',
  UPDATE_FAIL: '[UPDATE_FAIL] BUDGETS',
  //
  GET_START: '[GET_START] BUDGETS',
  GET_SUCCESS: '[GET_SUCCESS] BUDGETS',
  GET_FAIL: '[GET_FAIL] BUDGETS',
  SET_BUDGET_DATA: '[SET_BUDGET_DATA] BUDGETS',
  CLEAR_BUDGET_DATA: '[CLEAR_BUDGET_DATA] BUDGETS',
  //
  COMPLETE_SUCCESS: '[COMPLETE_SUCCESS] BUDGETS',
  COMPLETE_FAIL: '[COMPLETE_FAIL] BUDGETS',

  // ** New budget
  SET_SELECTED_BUDGET: '[SET_SELECTED_BUDGET] Budgets',
  SET_SELECTED_WORKER_CONTRACT: ' [SET_SELECTED_WORKER_CONTRACT]',
  SET_CONTRACT_TYPES: '[SET_CONTRACT_TYPES] Budgets',
  SET_DEDUCIBILITIES: '[SET_DEDUCIBILITIES] Budgets',
  SET_ITEMS: '[SET_ITEMS] Budgets',
  SET_TAXES: '[SET_TAXES] Budgets',
  SET_UNIT_MEASURES: '[SET_UNIT_MEASURES] Budgets',
  SET_WORKERS: '[SET_WORKERS] Budgets',

  SET_ALL_VARIANTS: '[SET_ALL_VARIANTS] Budgets',
  SET_ITEM_VARIANT_OPTIONS: '[SET_ITEM_VARIANT_OPTIONS] Budgets',
  SET_SALES_VARIANTS: '[SET_SALES_VARIANTS] Budgets',
  SET_PURCHASE_VARIANTS: '[SET_PURCHASE_VARIANTS] Budgets',

  CLEAR_SELECTED_BUDGET: '[CLEAR_SELECTED_BUDGET] Budgets',
  CLEAR_SELECTED_WORKER_CONTRACT: ' [CLEAR_SELECTED_WORKER_CONTRACT]',
  CLEAR_CONTRACT_TYPES: '[CLEAR_CONTRACT_TYPES] Budgets',
  CLEAR_DEDUCIBILITIES: '[CLEAR_DEDUCIBILITIES] Budgets',
  CLEAR_ITEMS: '[CLEAR_ITEMS] Budgets',
  CLEAR_TAXES: '[CLEAR_TAXES] Budgets',
  CLEAR_UNIT_MEASURES: '[CLEAR_UNIT_MEASURES] Budgets',
  CLEAR_WORKERS: '[CLEAR_WORKERS] Budgets',

  CLEAR_ALL_VARIANTS: '[CLEAR_ALL_VARIANTS] Budgets',
  CLEAR_ITEM_VARIANT_OPTIONS: '[CLEAR_ITEM_VARIANT_OPTIONS] Budgets',
  CLEAR_SALES_VARIANTS: '[CLEAR_SALES_VARIANTS] Budgets',
  CLEAR_PURCHASE_VARIANTS: '[CLEAR_PURCHASE_VARIANTS] Budgets',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/budgets`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    http(`api/budgets/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedBudget" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteBudgetFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const completeBudget = ({ id, index }) => {
  return (dispatch) => {
    http(`api/budgets/${id}/complete-budget`, { method: 'put' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="completedBudget" />))
        dispatch({ type: ActionTypes.COMPLETE_SUCCESS })
        dispatch(getList())
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="completeBudgetFail" />, 'error'))
      })
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http('api/budgets', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newBudget" />))
        history.push('/budgets')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newBudgetFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const fillInBudget = ({ payload, history, budget_id }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http(`/api/budget/${budget_id}/save-budget-wizard`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="filledInBudget" />))
        history.push('/budgets')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="fillInBudgetFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, budgetId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/budgets/${budgetId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedBudget" />))
        history.push('/budgets')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateBudgetFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (budgetId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: budgetId })
    http(`api/budgets/${budgetId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setBudgetData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_BUDGET_DATA, payload })
  }
}

export const clearBudgetData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_BUDGET_DATA })
  }
}

// ** New budget
export const addBudget = ({ payload, history }) => {
  return (dispatch) => {
    http('api/budgets', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newBudget" />))
        history.push('/budgets')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newBudgetFail" />, 'error'))
      })
  }
}

export const updateBudget = ({ budgetId, payload, history }) => {
  return (dispatch) => {
    http(`api/budgets/${budgetId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedBudget" />))
        history.push('/budgets')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateBudgetFail" />, 'error'))
      })
  }
}

export const fastFillBudget = (budgetId, payload, history) => {
  return (dispatch) => {
    http(`api/budgets/${budgetId}/fast-filling`, { method: 'POST', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="filledInBudget" />))
        history.push('/budgets')
      })
      .catch(() => dispatch(showToast(<InlineToast title="Error" description="filledInBudgetFail" />), 'error'))
  }
}

export const getSelectedBudget = (budgetId) => {
  return (dispatch) => {
    http(`api/budgets/${budgetId}`).then((payload) => dispatch({ type: ActionTypes.SET_SELECTED_BUDGET, payload }))
  }
}

export const getFastFillingInfo = (budgetId) => {
  return (dispatch) => {
    http(`api/budgets/${budgetId}/fast-filling`).then((response) => {
      const { budget, contract_types, deducibilities, items, taxes, unit_measures, workers } = response
      dispatch({ type: ActionTypes.SET_SELECTED_BUDGET, payload: budget })
      dispatch({ type: ActionTypes.SET_CONTRACT_TYPES, payload: contract_types })
      dispatch({ type: ActionTypes.SET_DEDUCIBILITIES, payload: deducibilities })
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_TAXES, payload: taxes })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: unit_measures })
      dispatch({ type: ActionTypes.SET_WORKERS, payload: workers })
    })
  }
}

export const setFastFillingUtils = ({ allVariants, itemVariantOptions }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ALL_VARIANTS, payload: allVariants })
    dispatch({ type: ActionTypes.SET_ITEM_VARIANT_OPTIONS, payload: itemVariantOptions })
  }
}

export const setBudgetSalesVariants = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_SALES_VARIANTS, payload })
  }
}

export const setBudgetPurchaseVariants = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_PURCHASE_VARIANTS, payload })
  }
}

export const setBudgetContractCreateUtils = (id) => {
  return (dispatch) =>
    http(`api/budgets/${id}/contracts/create`).then((response) => {
      const { code, ...payload } = response
      dispatch(setContractCode(code))
      dispatch(setContractCreateUtils(payload))
    })
}

export const addBudgetContract = ({ id, payload, history }) => {
  return (dispatch) =>
    http(`api/budgets/${id}/contracts`, { method: 'POST', body: payload })
      .then((response) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.push(`/budgets/${id}/contracts`)
      })
      .catch((error) => dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, error)))
}

export const getBudgetWorkerContractsCreate = ({ id }) => {
  return (dispatch) =>
    http(`api/budgets/${id}/workers-contracts/create`).then((response) => {
      const { types, workers } = response

      dispatch({ type: ActionTypes.SET_WORKERS, payload: workers })
      dispatch({ type: ActionTypes.SET_CONTRACT_TYPES, payload: types })
    })
}

export const addBudgetWorkerContract = ({ budget_id, payload, history }) => {
  return (dispatch) =>
    http(`api/budgets/${budget_id}/workers-contracts`, { method: 'POST', body: payload })
      .then((response) => {
        dispatch(showToast(<InlineToast title="Success" description="newContract" />))
        history.push(`/budgets/${budget_id}/employee-contracts`)
      })
      .catch((error) => {
        console.log(error)  
        dispatch(showToast(<InlineToast title="Failed" description="newContractFail" />, "error"))
      })
}

export const updateBudgetWorkerContract = ({ budget_id, contract_id, payload, history }) => {
  return (dispatch) =>
    http(`api/budgets/${budget_id}/workers-contracts/${contract_id}`, { method: 'PUT', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="updateContract" />))
        history.push(`/budgets/${budget_id}/employee-contracts`)
      })
      .catch((error) => dispatch(showToast(<InlineToast title="Failed" description="updateContractFail" />, "error")))
}

export const getBudgetWorkerContractEdit = ({ budget_id, contract_id }) => {
  return (dispatch) =>
    http(`api/budgets/${budget_id}/workers-contracts/${contract_id}/edit`).then((response) => {
      const { types, contract } = response

      dispatch({ type: ActionTypes.SET_CONTRACT_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_SELECTED_WORKER_CONTRACT, payload: contract })
    })
}

export const setSelectedWorkerContract = ({ contract }) => {
  return (dispatch) => dispatch({ type: ActionTypes.SET_SELECTED_WORKER_CONTRACT, payload: contract })
}

export const clearFastFillingInfo = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_BUDGET })
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_TYPES })
    dispatch({ type: ActionTypes.CLEAR_DEDUCIBILITIES })
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_TAXES })
    dispatch({ type: ActionTypes.CLEAR_UNIT_MEASURES })
    dispatch({ type: ActionTypes.CLEAR_WORKERS })
  }
}

export const clearFastFillingUtils = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ALL_VARIANTS })
    dispatch({ type: ActionTypes.CLEAR_ITEM_VARIANT_OPTIONS })
    dispatch({ type: ActionTypes.CLEAR_SALES_VARIANTS })
    dispatch({ type: ActionTypes.CLEAR_PURCHASE_VARIANTS })
  }
}

export const clearSelectedBudget = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_BUDGET })
  }
}

export const clearBudgetWorkerContractCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_TYPES })
    dispatch({ type: ActionTypes.CLEAR_WORKERS })
  }
}

export const clearBudgetWorkerContractEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CONTRACT_TYPES })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_WORKER_CONTRACT })
  }
}
