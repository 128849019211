// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const jwtOverrideConfig = {
  loginEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/api/login`,
  registerEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/api/register`,
  refreshEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/api/refresh-token`,
  logoutEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/auth/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageStatusMessage: 'statusmessage',
}
// eslint-disable-next-line react-hooks/rules-of-hooks
const { jwt } = useJwt(jwtOverrideConfig)
export default jwt
