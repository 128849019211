// **  Initial State
const initialState = {
  userData: null,
  userName: null,
  profilePhoto: null
}

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName],
      }
    case 'SET_NAME':
      return {
        ...state,
        userName: action.data
      }

    case 'SET_AVATAR':
      return {
        ...state,
        profilePhoto: action.data
      }
    case 'LOGINPARTIAL':
      return {
        ...state,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName],
      }
    default:
      return state
  }
}
