import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Price Lists',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Price Lists',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Price Lists',
  //
  DELETE_START: '[DELETE_START] Price Lists',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Price Lists',
  DELETE_FAIL: '[DELETE_FAIL] Price Lists',
  //
  ADD_START: '[ADD_START] Price Lists',
  ADD_SUCCESS: '[ADD_SUCCESS] Price Lists',
  ADD_FAIL: '[ADD_FAIL] Price Lists',
  //
  UPDATE_START: '[UPDATE_START] Price Lists',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Price Lists',
  UPDATE_FAIL: '[UPDATE_FAIL] Price Lists',
  //
  GET_START: '[GET_START] Price Lists',
  GET_SUCCESS: '[GET_SUCCESS] Price Lists',
  GET_FAIL: '[GET_FAIL] Price Lists',
  SET_LIST_DATA: '[SET_LIST_DATA] Price Lists',
  SET_VARIANT_LIST: '[SET_VARIANT_LIST] Price Lists',
  CLEAR_LIST_DATA: '[CLEAR_LIST_DATA] Price Lists',

  // ** New pricelists
  SET_ITEMS: '[SET_ITEMS] Price Lists',
  SET_TYPES: '[SET_TYPES] Price Lists',
  SET_SELECTED_PRICE_LIST: '[SET_SELECTED_PRICE_LIST] Price Lists',
  UPDATE_PRICE_LIST_TYPE: '[UPDATE_PRICE_LIST_TYPE] Price Lists',
  CLEAR_ITEMS: '[CLEAR_ITEMS] Price Lists',
  CLEAR_TYPES: '[CLEAR_TYPES] Price Lists',
  CLEAR_PRICE_LIST_TYPE: '[CLEAR_PRICE_LIST_TYPE] Price Lists',
  CLEAR_SELECTED_PRICE_LIST: '[CLEAR_SELECTED_PRICE_LIST] Price Lists',
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/price-lists`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const getVariantList = () => {
  return (dispatch) => {
    http(`/api/price-lists/item-variants`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.SET_VARIANT_LIST,
          payload,
        })
      )
      .catch((error) => {
        console.log(error)
      })
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    http(`api/price-lists/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedPriceList" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deletePriceListFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })
    http('api/price-lists', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newPriceList" />))
        history.push('/price-lists')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newPriceListFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, priceListId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })
    http(`api/price-lists/${priceListId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedPriceList" />))
        history.push('/price-lists')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updatePriceListFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (priceListId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: priceListId })
    http(`api/price-lists/${priceListId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setListData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_LIST_DATA, payload })
  }
}

export const clearListData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_LIST_DATA })
  }
}

// ** New price lists
export const addPriceList = ({ payload, history }) => {
  return (dispatch) => {
    http('api/price-lists', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newPriceList" />))
        history.push('/price-lists')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newPriceListFail" />, 'error'))
      })
  }
}

export const updatePriceList = ({ payload, priceListId, history}) => {
  return (dispatch) => {
    http(`api/price-lists/${priceListId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedPriceList" />))
        history.push('/price-lists')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updatePriceListFail" />, 'error'))
      })
  }
}

export const setPriceListCreate = () => {
  return (dispatch) => {
    http('api/price-lists/create').then((response) => {
      const { types, items } = response
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
    })
  }
}

export const setPriceListEdit = priceListId => {
  return dispatch => {
    http(`api/price-lists/${priceListId}/edit`).then(response => {
      const { types, items, price_list } = response
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_SELECTED_PRICE_LIST, payload: price_list })
    })
  }
}

export const updatePriceListType = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_PRICE_LIST_TYPE, payload })
  }
}

export const clearPriceListCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_TYPES })
  }
}

export const clearPriceListEdit = () => {
  return dispatch => {
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_PRICE_LIST })
  }
}

export const clearPriceListType = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_PRICE_LIST_TYPE })
  }
}
