import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] Notifications',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Notifications',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Notifications',
  UPDATE_STATUS: '[UPDATE_STATUS] Notifications'
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http('/api/notifications', {
      body: {},
    })
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const updateStatus = (id) => {
  return (dispatch) => {
    http(`/api/notifications/${id}`, { method: 'put'})
      .then((payload) => { })
      .catch((error) => { }
      )
  }
}
