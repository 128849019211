import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  listData: {},
  variants: [],

  // ** New price lists
  items: [],
  types: [],
  unitMeasures: [],
  priceListType: null,
  selectedPriceList: {},
}

export const priceLists = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.SET_LIST_DATA:
      return {
        ...state,
        listData: { ...state.listData, ...payload },
      }

    case ActionTypes.SET_VARIANT_LIST:
      return {
        ...state,
        variants: payload,
      }

    case ActionTypes.CLEAR_LIST_DATA:
      return {
        ...state,
        listData: {},
        variants: [],
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    // ** New price lists
    case ActionTypes.SET_ITEMS:
      return {
        ...state,
        items: payload,
      }

    case ActionTypes.SET_TYPES:
      return {
        ...state,
        types: payload,
      }

    case ActionTypes.SET_SELECTED_PRICE_LIST:
      return {
        ...state,
        selectedPriceList: payload,
      }

    case ActionTypes.UPDATE_PRICE_LIST_TYPE:
      return {
        ...state,
        priceListType: payload,
      }

    case ActionTypes.CLEAR_ITEMS:
      return {
        ...state,
        items: [],
      }

    case ActionTypes.CLEAR_TYPES:
      return {
        ...state,
        types: [],
      }

    case ActionTypes.CLEAR_PRICE_LIST_TYPE:
      return {
        ...state,
        priceListType: null,
      }

    case ActionTypes.CLEAR_SELECTED_PRICE_LIST:
      return {
        ...state,
        selectedPriceList: {},
      }

    default:
      return state
  }
}
