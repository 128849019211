import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
    user: {},
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  paymentData: {},

  // ** new contract payments
  types: [],
  methods: [],
  selectedPayment: {},
}

export const contractPayments = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: payload,
      }

    case ActionTypes.SET_PAYMENT_DATA:
      return {
        ...state,
        paymentData: { ...state.paymentData, ...payload },
      }

    case ActionTypes.CLEAR_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {},
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    // ** new contract payments
    case ActionTypes.SET_METHODS:
      return {
        ...state,
        methods: payload,
      }

    case ActionTypes.SET_TYPES:
      return {
        ...state,
        types: payload,
      }

    case ActionTypes.SET_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: payload,
      }

    case ActionTypes.CLEAR_METHODS:
      return {
        ...state,
        methods: [],
      }

    case ActionTypes.CLEAR_TYPES:
      return {
        ...state,
        types: [],
      }

    case ActionTypes.CLEAR_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: {},
      }

    default:
      return state
  }
}
