import { ActionTypes } from './actions'

const initialState = {
  listing: {
    data: [],
    error: null,
    isLoading: false,
  },
  get: {
    isLoading: false,
    error: null,
    user: {},
  },
  add: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  employeeInfo: {},
  employeeRegistry: {},

  // ** new employee
  classifications: [],
  countries: [],
  genders: [],
  officesUnits: [],
  isRegisteredUser: false,
  registeredUserData: {},
  selectedWorker: {},
}

export const employees = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.CLEAR_EMPLOYEE_DATA:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    case ActionTypes.SET_EMPLOYEE_REGISTRY:
      return {
        ...state,
        employeeRegistry: payload,
      }

    case ActionTypes.SET_EMPLOYEE_INFO:
      return {
        ...state,
        employeeInfo: payload,
      }

    case ActionTypes.CLEAR_REGISTRY_INFO:
      return {
        ...state,
        employeeInfo: {},
        employeeRegistry: {},
      }

    // ** New employee
    case ActionTypes.SET_CLASSIFICATIONS:
      return {
        ...state,
        classifications: payload,
      }

    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      }

    case ActionTypes.SET_GENDERS:
      return {
        ...state,
        genders: payload,
      }

    case ActionTypes.SET_OFFICES_UNITS:
      return {
        ...state,
        officesUnits: payload,
      }

    case ActionTypes.SET_IS_REGISTERED_USER:
      return {
        ...state,
        isRegisteredUser: payload,
      }

    case ActionTypes.SET_REGISTERED_USER_DATA:
      return {
        ...state,
        registeredUserData: payload,
      }

    case ActionTypes.SET_SELECTED_WORKER:
      return {
        ...state,
        selectedWorker: payload,
      }

    case ActionTypes.CLEAR_CLASSIFICATIONS:
      return {
        ...state,
        classifications: [],
      }

    case ActionTypes.CLEAR_COUNTRIES:
      return {
        ...state,
        countries: [],
      }

    case ActionTypes.CLEAR_GENDERS:
      return {
        ...state,
        genders: [],
      }

    case ActionTypes.CLEAR_OFFICES_UNITS:
      return {
        ...state,
        officesUnits: [],
      }

    case ActionTypes.CLEAR_IS_REGISTERED_USER:
      return {
        ...state,
        isRegisteredUser: false,
      }

    case ActionTypes.CLEAR_REGISTERED_USER_DATA:
      return {
        ...state,
        registeredUserData: {},
      }

    case ActionTypes.CLEAR_SELECTED_WORKER:
      return {
        ...state,
        selectedWorker: {},
      }

    default:
      return state
  }
}
