import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] INVOICES',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] INVOICES',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] INVOICES',
  //
  DELETE_START: '[DELETE_START] INVOICES',
  DELETE_SUCCESS: '[DELETE_SUCCESS] INVOICES',
  DELETE_FAIL: '[DELETE_FAIL] INVOICES',
  //
  ADD_START: '[ADD_START] INVOICES',
  ADD_SUCCESS: '[ADD_SUCCESS] INVOICES',
  ADD_FAIL: '[ADD_FAIL] INVOICES',
  //
  UPDATE_START: '[UPDATE_START] INVOICES',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] INVOICES',
  UPDATE_FAIL: '[UPDATE_FAIL] INVOICES',
  //
  GET_START: '[GET_START] INVOICES',
  GET_SUCCESS: '[GET_SUCCESS] INVOICES',
  GET_FAIL: '[GET_FAIL] INVOICES',
  CLEAR_INVOICE_DATA: '[CLEAR_INVOICE_DATA] INVOICES',
}

export const getList = ({ limit, page }) => {
  let url = ''
  limit && page ? url = `/api/invoice?per_page=${limit}&page=${page}` : url = '/api/invoice'
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http(url)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const addItem = ({ payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('api/invoice', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newInvoice" />))
        history.push('/invoices')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newInvoiceFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ payload, history, invoiceId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/invoice/${invoiceId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedInvoice" />))
        history.push('/invoices')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateInvoiceFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (invoiceId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: invoiceId })
    http(`api/invoice/${invoiceId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearInvoiceData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_INVOICE_DATA })
  }
}
