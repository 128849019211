import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  SET_ACTIVE: '[SET_ACTIVE] Companies',
  SET_COMPANY_REGISTRY: '[SET_COMPANY_REGISTRY] Companies',
  SET_COMPANY_ADDRESS: '[SET_COMPANY_ADDRESS] Companies',
  LOAD_LIST_START: '[LOAD_LIST_START] Companies',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] Companies',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] Companies',
  DELETE_START: '[DELETE_START] Companies',
  DELETE_SUCCESS: '[DELETE_SUCCESS] Companies',
  DELETE_FAIL: '[DELETE_FAIL] Companies',
  ADD_START: '[ADD_START] Companies',
  ADD_SUCCESS: '[ADD_SUCCESS] Companies',
  ADD_FAIL: '[ADD_FAIL] Companies',
  UPDATE_START: '[UPDATE_START] Companies',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Companies',
  UPDATE_FAIL: '[UPDATE_FAIL] Companies',
  GET_START: '[GET_START] Companies',
  GET_SUCCESS: '[GET_SUCCESS] Companies',
  GET_FAIL: '[GET_FAIL] Companies',
  CLEAR_COMPANY_DATA: '[CLEAR_COMPANY_DATA] Companies',
  CLEAR_REGISTRY_ADDRESS: '[CLEAR_REGISTRY_ADDRESS] Companies',
  SET_OFFICES: '[SET_OFFICES] Companies',

  // ** New company
  GET_COMPANY: '[GET_COMPANY] Companies',
  SET_LEGAL_ENITITES: '[SET_LEGAL_ENITITES] Companies',
  SET_COUNTRIES: '[SET_COUNTRIES] Companies',
  UPDATE_SELECTED_COUNTRY: '[UPDATE_SELECTED_COUNTRY] Companies',
  SET_IVA_REGIMES: '[SET_IVA_REGIMES] Companies',
  SET_IVA_LIQUIDATIONS: '[SET_IVA_LIQUIDATIONS] Companies',
  CLEAR_GET_COMPANY: '[CLEAR_GET_COMPANY] Companies',
  CLEAR_LEGAL_ENITITES: '[CLEAR_LEGAL_ENITITES] Companies',
  CLEAR_COUNTRIES: '[CLEAR_COUNTRIES] Companies',
  CLEAR_SELECTED_COUNTRY: '[CLEAR_SELECTED_COUNTRY] Companies',
  CLEAR_IVA_REGIMES: '[CLEAR_IVA_REGIMES] Companies',
  CLEAR_IVA_LIQUIDATIONS: '[CLEAR_IVA_LIQUIDATIONS] Companies',
}

export const setActive = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ACTIVE, payload })
    if (payload) window.localStorage.setItem('activeCompany', payload)
    else window.localStorage.removeItem('activeCompany')
  }
}

export const setOffices = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_OFFICES, payload })
  }
}

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })
    http('/api/companies')
      .then((payload) => {
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    http(`/api/companies/${id}`, { method: 'delete' })
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
        dispatch(showToast(<InlineToast title="Success" description="deletedCompany" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteCompanyFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

/* export const createCompany = (payload, { history }) => {
  return (dispatch) => {
    let apiResponse = null
    dispatch({ type: ActionTypes.ADD_START, payload })
    http('api/companies', { method: 'post', body: payload })
      .then((response) => {
        apiResponse = { ...response }
        dispatch(showToast(<InlineToast title="Success" description="newCompany" />))
        history.push('/companies')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload: response.id })
        return apiResponse
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newCompanyFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
    dispatch({ type: ActionTypes.CLEAR_REGISTRY_ADDRESS })
  }
} */

export const addPayment = ({ id, history }) => {
  return (dispatch) => {
    //dispatch({ type: ActionTypes.ADD_START, payload })

    http(`/billing/company/${id}`, { method: 'get' })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="paymentSuccess" />))
        //history.push('/companies')
        //dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
        return payload
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="paymentFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

/* export const updateCompany = ({ companyId, payload }, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload })
    http(`/api/companies/${companyId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedCompany" />))
        history.push('/companies')
        window.location.reload()
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
        dispatch({ type: ActionTypes.CLEAR_REGISTRY_ADDRESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateCompanyFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
} */

export const getCompany = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: id })
    http(`/api/companies/${id}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const setCompanyRegistry = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_COMPANY_REGISTRY, payload })
  }
}
export const setCompanyAddress = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_COMPANY_ADDRESS, payload })
  }
}

export const clearCompanyData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_COMPANY_DATA })
  }
}

// ** New companies
export const createCompany = ({ payload, history }) => {
  return (dispatch) => {
    http('api/companies', { method: 'post', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="newCompany" />))
        history.push('/companies')
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newCompanyFail" />, 'error'))
      })
  }
}

export const updateCompany = ({ payload, history, companyId }) => {
  return (dispatch) => {
    http(`api/companies/${companyId}`, { method: 'put', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="updatedCompany" />))
        history.push('/companies')
        window.location.reload()
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateCompanyFail" />, 'error'))
      })
  }
}

export const getSelectedCompany = (id) => {
  return (dispatch) => {
    http(`api/companies/${id}/edit`).then((response) => {
      const { company, countries, iva_liquidations, iva_regimes, legal_entities } = response
      dispatch({ type: ActionTypes.GET_COMPANY, payload: company })
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.SET_LEGAL_ENITITES, payload: legal_entities })
      dispatch({ type: ActionTypes.SET_IVA_LIQUIDATIONS, payload: iva_liquidations })
      dispatch({ type: ActionTypes.SET_IVA_REGIMES, payload: iva_regimes })
    })
  }
}

export const setCompanyCreate = () => {
  return (dispatch) => {
    http('api/companies/create').then((response) => {
      const { countries, iva_liquidations, iva_regimes, legal_entities } = response
      dispatch({ type: ActionTypes.SET_COUNTRIES, payload: countries })
      dispatch({ type: ActionTypes.SET_LEGAL_ENITITES, payload: legal_entities })
      dispatch({ type: ActionTypes.SET_IVA_LIQUIDATIONS, payload: iva_liquidations })
      dispatch({ type: ActionTypes.SET_IVA_REGIMES, payload: iva_regimes })
    })
  }
}

export const updateIsPhysicalPerson = (state) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_IS_PHYSICAL_PERSON, payload: state })
  }
}

export const updateSelectedCountry = (country) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SELECTED_COUNTRY, payload: country })
  }
}

export const clearGetSelectedCompany = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_GET_COMPANY })
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
    dispatch({ type: ActionTypes.CLEAR_LEGAL_ENITITES })
    dispatch({ type: ActionTypes.CLEAR_IVA_LIQUIDATIONS })
    dispatch({ type: ActionTypes.CLEAR_IVA_REGIMES })
  }
}

export const clearCompanyCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_COUNTRIES })
    dispatch({ type: ActionTypes.CLEAR_LEGAL_ENITITES })
    dispatch({ type: ActionTypes.CLEAR_IVA_LIQUIDATIONS })
    dispatch({ type: ActionTypes.CLEAR_IVA_REGIMES })
  }
}

export const clearIsPhysicalPerson = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_IS_PHYSICAL_PERSON })
  }
}

export const clearSelectedCountry = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SELECTED_COUNTRY })
  }
}
