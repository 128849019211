import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { addIndexToData, http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] CATEGORIES',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] CATEGORIES',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] CATEGORIES',
  //
  DELETE_START: '[DELETE_START] CATEGORIES',
  DELETE_SUCCESS: '[DELETE_SUCCESS] CATEGORIES',
  DELETE_FAIL: '[DELETE_FAIL] CATEGORIES',
  //
  ADD_START: '[ADD_START] CATEGORIES',
  ADD_SUCCESS: '[ADD_SUCCESS] CATEGORIES',
  ADD_FAIL: '[ADD_FAIL] CATEGORIES',
  //
  UPDATE_START: '[UPDATE_START] CATEGORIES',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] CATEGORIES',
  UPDATE_FAIL: '[UPDATE_FAIL] CATEGORIES',
  //
  GET_START: '[GET_START] CATEGORIES',
  GET_SUCCESS: '[GET_SUCCESS] CATEGORIES',
  GET_FAIL: '[GET_FAIL] CATEGORIES',
  CLEAR_CATEGORY_DATA: '[CLEAR_CATEGORY_DATA] CATEGORIES',
}

export const getList = (companyId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/categories`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload: addIndexToData(payload),
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ id, index }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_START, payload: index })

    http(`api/categories/${id}`, { method: 'delete' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="deletedCategory" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteCategoryFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = (payload, { history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http('api/categories', { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newCategory" />))
        history.push('/item-categories')
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newCategoryFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ id, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/categories/${id}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedCategory" />))
        history.push('/item-categories')
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateCategoryFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: categoryId })

    http(`api/categories/${categoryId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearCategoryData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CATEGORY_DATA })
  }
}
