import { InlineToast } from '@src/views/components/InlineToast'
import { hidePrompt, showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  UPDATE_START: '[UPDATE_START] Work Hours',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] Work Hours',
  UPDATE_FAIL: '[UPDATE_FAIL] Work Hours',
  //
  GET_START: '[GET_START] Work Hours',
  GET_SUCCESS: '[GET_SUCCESS] Work Hours',
  GET_FAIL: '[GET_FAIL] Work Hours',
}

export const updateItem = ({ asaId, data }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: data })
    http(`workingday/update/${asaId}`, { method: 'put', body: data })
      .then((payload) => {
        dispatch(
          showToast(
            <InlineToast
              title="Success"
              description="updatedWH"
            />
          )
        )
        dispatch(hidePrompt())
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(
          showToast(
            <InlineToast
              title="Failed"
              description="updateWHFail"
            />,
            'error'
          )
        )
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = (asaId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START })
    http(`workingday/show/${asaId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}
