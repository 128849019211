import { ActionTypes } from './actions'

const initialState = {
  formData: {},
  listing: {
    data: [],
    isLoading: false,
    error: null,
  },
  get: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
}

export const officeAuthorization = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: payload,
      }
    case ActionTypes.CLEAR_FORM_DATA:
      return {
        ...state,
        formData: {},
      }

    //

    case ActionTypes.LOAD_LIST_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          error: null,
          isLoading: true,
        },
      }
    case ActionTypes.LOAD_LIST_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: payload,
        },
      }
    case ActionTypes.LOAD_LIST_FAIL:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.DELETE_START:
      return {
        ...state,
        listing: {
          ...state.listing,
          data: state.listing.data.filter((_, itemIndex) => itemIndex !== payload),
        },
      }

    //

    case ActionTypes.ADD_START:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
        },
      }
    case ActionTypes.ADD_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.UPDATE_START:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
        },
      }

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }

    //

    case ActionTypes.GET_START:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          data: payload,
        },
      }

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }
    case ActionTypes.CLEAR_DATA:
      return {
        ...state,
        get: {
          ...state.add,
          isLoading: false,
          error: payload,
        },
      }
    default:
      return state
  }
}
