import { getHeaders } from '@utils'
import axios from 'axios'

export const updateUserDetails = async (data) => {
  try {
    const apiResponse = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
      data,
      withCredentials: true,
      headers: getHeaders(),
    })
    if (apiResponse.status === 201) {
      return apiResponse.data
    }
  } catch (e) { console.log(e) }
}

export const updateUserInfo = async (data) => {
  try {
    const apiResponse = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/update-profile`,
      data,
      withCredentials: true,
      headers: getHeaders(),
    })
    if (apiResponse.status === 201) {
      return apiResponse.data
    }
  } catch (e) { console.log(e) }
}

export const editUserDetails = async ({ id, data }) => {
  try {
    const apiResponse = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/${id}`,
      data,
      withCredentials: true,
      headers: getHeaders(),
    })
    if (apiResponse.status === 200) {
      return apiResponse
    }
  } catch (e) { console.log(e) }
}


export const getUserDetails = async () => {
  try {
    const apiResponse = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
      withCredentials: true,
      headers: getHeaders(),
    })
    if (apiResponse.status === 200) {
      return apiResponse.data
    }
  } catch (e) { console.log(e) }
}

export const changePassword = async (data) => {
  try {
    const apiResponse = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/password`,
      data,
      headers: getHeaders(),
    })
    return apiResponse
  } catch (e) {
    return e.response
  }
}

export const changeGeneralInfo = async ({ data, user_id }) => {
  try {
    const apiResponse = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/${user_id}?_method=PUT`,
      data,
      //withCredentials: true,
      headers: getHeaders(),
    })
    return apiResponse
  } catch (e) {
    return e.response
  }
}

export const updateLanguage = async (data) => {
  const apiResponse = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/select-language`,
    data,
  })
}
