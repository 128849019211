import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  LOAD_LIST_START: '[LOAD_LIST_START] VARIANTS',
  LOAD_LIST_SUCCESS: '[LOAD_LIST_SUCCESS] VARIANTS',
  LOAD_LIST_FAIL: '[LOAD_LIST_FAIL] VARIANTS',
  //
  DELETE_START: '[DELETE_START] VARIANTS',
  DELETE_SUCCESS: '[DELETE_SUCCESS] VARIANTS',
  DELETE_FAIL: '[DELETE_FAIL] VARIANTS',
  //
  ADD_START: '[ADD_START] VARIANTS',
  ADD_SUCCESS: '[ADD_SUCCESS] VARIANTS',
  ADD_FAIL: '[ADD_FAIL] VARIANTS',
  //
  UPDATE_START: '[UPDATE_START] VARIANTS',
  UPDATE_SUCCESS: '[UPDATE_SUCCESS] VARIANTS',
  UPDATE_FAIL: '[UPDATE_FAIL] VARIANTS',
  //
  GET_START: '[GET_START] VARIANTS',
  GET_SUCCESS: '[GET_SUCCESS] VARIANTS',
  GET_FAIL: '[GET_FAIL] VARIANTS',
  CLEAR_VARIANT_DATA: '[CLEAR_VARIANT_DATA] VARIANTS',
  SET_VARIANT_DATA: 'SET_VARIANT_DATA VARIANTS',
}

export const getList = (itemId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_START })

    http(`/api/items/${itemId}/variants`)
      .then((payload) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_SUCCESS,
          payload,
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.LOAD_LIST_FAIL,
          payload: error.message,
        })
      )
  }
}

export const deleteItem = ({ itemId, variantId, index }) => {
  return (dispatch) => {
    http(`/api/items/${itemId}/variants/${variantId}`, { method: 'delete' })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="deletedVariant" />))
        dispatch({ type: ActionTypes.DELETE_SUCCESS })
        dispatch({ type: ActionTypes.DELETE_START, payload: index })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="deleteVariantFail" />, 'error'))
        dispatch({
          type: ActionTypes.DELETE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const addItem = ({ itemId, payload, history }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_START, payload })

    http(`api/items/${itemId}/variants`, { method: 'post', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="newVariant" />))
        history.push(`/items/${itemId}/variants`)
        dispatch({ type: ActionTypes.ADD_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="newVariantFail" />, 'error'))
        dispatch({
          type: ActionTypes.ADD_FAIL,
          payload: error.message,
        })
      })
  }
}

export const updateItem = ({ itemId, payload, history, variantId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_START, payload: payload })

    http(`api/items/${itemId}/variants/${variantId}`, { method: 'put', body: payload })
      .then((payload) => {
        dispatch(showToast(<InlineToast title="Success" description="updatedVariant" />))
        history.push(`/items/${itemId}/variants`)
        dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload })
      })
      .catch((error) => {
        dispatch(showToast(<InlineToast title="Failed" description="updateVariantFail" />, 'error'))
        dispatch({
          type: ActionTypes.UPDATE_FAIL,
          payload: error.message,
        })
      })
  }
}

export const getItem = ({ itemId, variantId }) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_START, payload: variantId })
    http(`api/items/${itemId}/variants/${variantId}`)
      .then((payload) => dispatch({ type: ActionTypes.GET_SUCCESS, payload }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_FAIL,
          payload: error.message,
        })
      )
  }
}

export const clearVariantData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_VARIANT_DATA })
  }
}

export const setVariantData = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_VARIANT_DATA, payload })
  }
}

export const clearListing = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_LIST_FAIL })
  }
}
