import { InlineToast } from '@src/views/components/InlineToast'
import { showToast } from '@store/layout/actions'
import { http } from '@utils'

export const ActionTypes = {
  SET_TYPES: '[SET_TYPES] Movements',
  SET_ITEMS: '[SET_ITEMS] Movements',
  SET_CONTRACTS: '[SET_CONTRACTS] Movements',
  SET_UNIT_MEASURES: '[SET_UNIT_MEASURES] Movements',
  SET_OFFICES_UNITS: '[SET_OFFICES_UNITS] Movements',
  SET_SELECTED_MOVEMENT: '[SET_SELECTED_MOVEMENT] Movements',

  CLEAR_TYPES: '[CLEAR_TYPES] Movements',
  CLEAR_ITEMS: '[CLEAR_ITEMS] Movements',
  CLEAR_CONTRACTS: '[CLEAR_CONTRACTS] Movements',
  CLEAR_UNIT_MEASURES: '[CLEAR_UNIT_MEASURES] Movements',
  CLEAR_OFFICES_UNITS: '[CLEAR_OFFICES_UNITS] Movements',
  CLEAR_SELECTED_MOVEMENT: '[CLEAR_SELECTED_MOVEMENT] Movements',
}

export const addMovement = ({ payload, history }) => {
  return (dispatch) => {
    http('api/movements', { method: 'POST', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="movementSuccess" />))
        history.push("/movements")
      })
      .catch(() => {
        dispatch(showToast(<InlineToast title="Error" description="movementFail" />, 'error'))
      })
  }
}

export const updateMovement = ({ payload, id, history }) => {
  return (dispatch) => {
    http(`api/movements/${id}`, { method: 'PUT', body: payload })
      .then(() => {
        dispatch(showToast(<InlineToast title="Success" description="movementUpdateSuccess" />))
        history.push("/movements")
      })
      .catch(() => {
        dispatch(showToast(<InlineToast title="Error" description="movementUpdateFail" />, 'error'))
      })
  }
}

export const deleteMovement = (id) => {
    return dispatch => {
        http(`api/movements/${id}`, {method: "DELETE"}).then(response => {
            dispatch(showToast(<InlineToast title="Success" description="movementDeleteSuccess" />))
        }).catch(() => {
            dispatch(showToast(<InlineToast title="Error" description="movementDeleteFail" />, "error"))
        })
    }
}

export const getMovementCreate = () => {
  return (dispatch) => {
    http('/api/movements/create').then((response) => {
      const { types, items, contracts, unit_measures, offices_units } = response
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_CONTRACTS, payload: contracts })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: unit_measures })
      dispatch({ type: ActionTypes.SET_OFFICES_UNITS, payload: offices_units })
    })
  }
}

export const getMovementEdit = (id) => {
  return (dispatch) => {
    http(`/api/movements/${id}/edit`).then((response) => {
      const { movement, types, items, contracts, unit_measures, offices_units } = response
      dispatch({ type: ActionTypes.SET_TYPES, payload: types })
      dispatch({ type: ActionTypes.SET_ITEMS, payload: items })
      dispatch({ type: ActionTypes.SET_CONTRACTS, payload: contracts })
      dispatch({ type: ActionTypes.SET_UNIT_MEASURES, payload: unit_measures })
      dispatch({ type: ActionTypes.SET_OFFICES_UNITS, payload: offices_units })
      dispatch({ type: ActionTypes.SET_SELECTED_MOVEMENT, payload: movement })
    })
  }
}

export const clearMovementCreate = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_CONTRACTS })
    dispatch({ type: ActionTypes.CLEAR_UNIT_MEASURES })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
  }
}

export const clearMovementEdit = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_TYPES })
    dispatch({ type: ActionTypes.CLEAR_ITEMS })
    dispatch({ type: ActionTypes.CLEAR_CONTRACTS })
    dispatch({ type: ActionTypes.CLEAR_UNIT_MEASURES })
    dispatch({ type: ActionTypes.CLEAR_OFFICES_UNITS })
    dispatch({ type: ActionTypes.CLEAR_SELECTED_MOVEMENT })
  }
}
